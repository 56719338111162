import rfdcClone, { Options } from 'rfdc';

export function deepClone<T>(obj: T, options?: Options): T {
  const deepCloneFn = rfdcClone(options);
  return deepCloneFn<T>(obj);
}

export function enumObject<T, K extends keyof T>(obj: T): { keys: K[], values: T[K][], entries: [K, T[K]][] } {
  const keys: K[] = Object.keys(obj).filter(k => isNaN(Number(k))) as K[];

  return {
    keys,
    values: keys.map(k => obj[k]),
    entries: keys.map(k => [k, obj[k]])
  };
}
