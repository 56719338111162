














// Libraries
import { Vue, Component, Watch, Emit, Prop, Model } from 'vue-property-decorator';
import Fuse from 'fuse.js';
import { eventBus } from '@/utils/event-bus';
import { newGuid } from '@/utils/string';
import inputsTree from "@/store/inputs-tree";
import { debounce } from 'lodash';

@Component({
  name: 'object-search',
})
export default class ObjectSearch<T = Record<string, any>> extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public documents!: T[];
  @Prop({ default: () => ['name'] })
  public searchFields!: string[];
  @Prop({ default: null })
  public placeHolder!: string;
  @Model('input', { required: true, default: null })
  public searchResults!: string[];
  @Prop({ default: false, type: Boolean})
  public fuzzy: boolean;
  @Prop({ default: '' })
  public defaultSearchString: string;
  // VUEX
  // Properties
  public uuid: string = newGuid();
  public searchString: string = '';
  // Fields
  private fuseOptions: Fuse.IFuseOptions<T> = {
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.0,
    ignoreLocation: true,
    minMatchCharLength: 3,
    keys: this.searchFields
  };

  // Getters
  get clearVisible(): boolean {
    return this.searchString != null && this.searchString !== '';
  }   
 
  public showFocus() {
    eventBus.$emit('calculator-clear-focus', true);
  }

  // Lifecycle Handlers
  private mounted(): void {
    this.searchString = this.defaultSearchString;

    this.onSearchResultsChange(null);
    eventBus.$on('search-clicked', () => {
      this.$nextTick().then(() => {
        if (this.$refs.searchField != null) {
          const searchField: HTMLInputElement = this.$refs.searchField as HTMLInputElement;
          searchField.focus();
        }
      });
    });
  }

  // Private Methods
  public setSearchResults(): () => void {
    return debounce(() => {
      if (this.searchString.length > 0 && this.searchString.length < this.fuseOptions.minMatchCharLength) {
        return;
      }

      inputsTree.setSearchString(this.searchString);

      if (this.documents != null) {
        const fuse = new Fuse(this.documents, this.fuseOptions);
        const results = fuse.search(this.searchString).map((row: any) => row.item);
        this.onSearchResultsChange(results);
        this.showEmptyResults(this.searchString.trim() !== '' && !results.length);
      }
    }, 400);
  }

  public clearSearch(): void {
    this.searchString = '';
  }

  @Watch('fuzzy', { immediate: true })
  public fuzzyChange(fuzzyState: boolean): void {
    this.fuseOptions.threshold = fuzzyState ? 0.4 : 0.0;
  }
  @Watch('searchString')
  public onSearchStringChange(): void {
    this.setSearchResults()();
  }

  // Emitters
  @Emit('input')
  public onSearchResultsChange(results: any[]): void {}

  @Emit('showEmptyResults')
  public showEmptyResults(unused: boolean): void {}
}
