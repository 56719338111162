import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import Vue from 'vue';
import store from '.';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { Nil } from '@/utils/types';
import {
  IAssetReportVariableViewModel,
  VariableTreeNodeData,
  IUpdateVariableViewModel,
  VariableNodesTable,
  VariableTreeNode
} from '@/view-models/variables';
import { AssetTreeNodesNormalizer, getUniqueVariableKey } from '@/utils/tree/asset-node-converters';
import ReportAssetHierarchyService from '@/services/report-asset-hierarchy-service';
import AssetsService from '@/services/assets-service';
import { AccessSettingEnum, TreeFilterEnum, VariableMergeActionEnum } from '@/enums/variables';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import VariableEditorService from '@/services/variable-editor-service';
import { ITreeNode } from '@/view-models/tree';
import { ApiVersion } from '@/enums/api-version';
import { CurrentMode } from '@/enums/current-mode';
import editor from '@/store/editor';
import { deepClone } from '@/utils/object-utils';
import { normalizeNotes } from '@/utils/tree/tree-node-utils';
import eventBus, { Events } from '@/utils/event-bus';

export interface IInputsTreeModule {
  // State
  loadingNodesTable: boolean;
  inMergePhase: boolean;
  nodesTable: VariableNodesTable;
  rootAsset: Nil<IAssignmentTreeNodeViewModel>;
  selectedNode: VariableTreeNode;
  selectedNodesRecord: Record<string, string>;
  openNodesRecord: Record<string, string>;
  selectedNodeKeysForMerge: Array<string>;
  selectedTreeEnum: TreeFilterEnum;
  searchedNodeKeys: string[];
  searchedNodeKeysRecord: Record<string, string>;
  customParentNode: VariableTreeNode;
  searchString: string;
  // Getters
  readonly rootAssetNode: Nil<VariableTreeNode>;
  nodeByKey: (nodeKey: string, trees: VariableTreeNode[]) => VariableTreeNode;
  childNodesByParentNode: (node: VariableTreeNode, filtered?: boolean) => Array<VariableTreeNode>;
  selectedNodeIsClean: boolean;
  searchedNodesTable: VariableNodesTable;
  searchResultNodesTable: VariableNodesTable;
  // Mutations
  addNodeKeyForMerge(nodeKey: string): void;
  removeNodeKeyForMerge(nodeKey: string): void;
  setLoadingNodesTable(loading: boolean): void;
  setInMergePhase(merge: boolean): void;
  setSearchString(searchStr: string): void;
  setNodesTable(nodesTable: VariableNodesTable): void;
  setRootAsset(asset: Nil<IAssignmentTreeNodeViewModel>): void;
  selectNodes(nodes: Array<VariableTreeNode>): void;
  deselectNodes(nodes: Array<VariableTreeNode>): void;
  toggleSelectNode([node, batchNodeSelection]: [VariableTreeNode, boolean?]): void;
  toggleNodeIsOpen(node: VariableTreeNode): void;
  collapseAll(): void;
  updateVariableNode(payload: { variable: IAssetReportVariableViewModel,
  leadingVariable?: IAssetReportVariableViewModel }): void;
  setCustomParentNode(node: VariableTreeNode): void;
  // Actions
  loadTree(rootAsset: IAssignmentTreeNodeViewModel): Promise<void>;
  mergeSelectedNodes(newInputName: string): Promise<void>;
  setSelectedTreeFilter(filter: TreeFilterEnum): void;
  setSelectedTreeFilters(filters: TreeFilterEnum[]): void;
}

@Module({ dynamic: true, store, name: 'inputs-tree' })
export class InputsTreeModule extends VuexModule implements IInputsTreeModule {
  // State
  public loadingNodesTable: boolean = false;
  public inMergePhase: boolean = false;
  public nodesTable: VariableNodesTable = {};
  public rootAsset: Nil<IAssignmentTreeNodeViewModel> = null;
  public selectedNode: VariableTreeNode = null;
  public selectedNodesRecord: Record<string, string> = {};
  public openNodesRecord: Record<string, string> = {};
  public selectedNodeKeysForMerge: string[] = [];
  public selectedTreeEnum: TreeFilterEnum = TreeFilterEnum.All;
  public selectedTreeEnums: TreeFilterEnum[] = [];
  public searchedNodeKeys: string[] = [];
  public searchedNodeKeysRecord: Record<string, string> = {};
  public customParentNode: VariableTreeNode = null;
  public searchString: string = '';

  // Getters
  public get rootAssetNode(): Nil<VariableTreeNode> {
    return this.rootAsset == null ? null : this.nodesTable[this.rootAsset.key];
  }
  public get treeHelper(): TreeTraverseHelper<IAssetReportVariableViewModel> {
    return new TreeTraverseHelper<IAssetReportVariableViewModel>(this.nodesTable);
  }
  public get filteredTreeHelper(): TreeTraverseHelper<IAssetReportVariableViewModel> {
    return new TreeTraverseHelper<IAssetReportVariableViewModel>(this.filteredNodesTable);
  }
  public get selectedNodeKeys(): string[] {
    return Object.keys(this.selectedNodesRecord) ?? [];
  }
  public get selectedNodeIsClean(): boolean {
    return selectedNodeIsClean(this.selectedNode, this.nodesTable);
  }

  public get filteredNodesTable(): VariableNodesTable {
    const inputs: VariableNodesTable = {};
    let newTable: VariableNodesTable;

    const newTableKeys: string[] = [];

    Object.values(this.searchResultNodesTable).forEach((val) => {
      if (!val.isLeaf) {
        newTableKeys.push(val.key);
      } else {
        const enabledFilterPassed: boolean = !this.selectedTreeEnums.includes(TreeFilterEnum.Enabled) ||
          (val.data.accessSettings.includes(AccessSettingEnum.Dashboards) || val.data.accessSettings.includes(AccessSettingEnum.Reports));
        const customFilterPassed: boolean = !this.selectedTreeEnums.includes(TreeFilterEnum.Custom) ||
          val.data.type === TreeFilterEnum.Custom;
        const filterChecks: boolean[] = [enabledFilterPassed, customFilterPassed];

        if (filterChecks.every(c => c)) {
          newTableKeys.push(val.key);
        }
      }
    });

    if (newTableKeys.length > 0) {
      newTable = newTableKeys.reduce((acc: VariableNodesTable, key: string) => {
        acc[key] = this.nodesTable[key];
        return acc;
      }, {});
    }

    return newTable ?? inputs;
  }

  public get searchedNodesTable(): VariableNodesTable {
    if ( this.searchString === '') {
      return this.nodesTable;
    }
    const inputs: VariableNodesTable = {};
    Object.keys(this.nodesTable).forEach((k) => {
      const item = this.nodesTable[k];
      if (this.searchedNodeKeys?.includes (k)) {
        inputs[k] = item;
        let parentNode: VariableTreeNode;
        let currentInputNode: VariableTreeNode = this.nodesTable[item.parentKey];
        while (currentInputNode) {
          if (currentInputNode.parentKey === currentInputNode.key || Object.keys(inputs).includes(currentInputNode.key)) {
            break;
          }
          if (!Object.keys(inputs).includes(currentInputNode.key)) {
            inputs[currentInputNode.key] = this.nodesTable[currentInputNode.key];
          }
          parentNode = this.nodesTable[currentInputNode.parentKey];
          currentInputNode = parentNode;
        };
      }
    });
    return inputs;
  }

  public get searchResultNodesTable(): VariableNodesTable {
    if ( this.searchString === '') {
      return this.nodesTable;
    }
    const inputs: VariableNodesTable = {};
    Object.keys(this.nodesTable).forEach((k) => {
      const item = this.nodesTable[k];
      if (this.searchedNodeKeysRecord[k] != null) {
        inputs[k] = item;
        let parentNode: VariableTreeNode;
        let currentInputNode: VariableTreeNode = this.nodesTable[item.parentKey];
        while (currentInputNode) {
          if (currentInputNode.parentKey === currentInputNode.key || inputs[currentInputNode.key] != null) {
            break;
          }
          if (inputs[currentInputNode.key] == null) {
            inputs[currentInputNode.key] = this.nodesTable[currentInputNode.key];
          }
          parentNode = this.nodesTable[currentInputNode.parentKey];
          currentInputNode = parentNode;
        }
      }
    });
    return inputs;
  }

  public get nodeByKey() {
    return (nodeKey: string, trees: VariableTreeNode[]): VariableTreeNode => {
      for (const treeNode of trees) {
        const currentInputNode = recursiveFindNode(treeNode, nodeKey);
        if (currentInputNode != null) {
          return currentInputNode;
        }
      }
    };
  }

  public get childNodesByParentNode(): (node: VariableTreeNode, filtered?: boolean) => Array<VariableTreeNode> {
    return (node: VariableTreeNode, filtered?: boolean): Array<VariableTreeNode> => {
      const helper = filtered ? this.filteredTreeHelper : this.treeHelper;
      return node != null ? Array.from(new Set(helper.getChildrenNodes(node) ?? [])) : [];
    };
  }

  public get enrichFlag(): boolean {
    return true;
  }

  // Mutations
  @Mutation
  public resetState(): void {
    this.loadingNodesTable = false;
    this.inMergePhase = false;
    this.nodesTable = {};
    this.rootAsset = null;
    this.selectedNode = null;
    this.selectedNodesRecord = {};
    this.openNodesRecord = {};
    this.selectedNodeKeysForMerge = [];
    this.selectedTreeEnum = TreeFilterEnum.All;
    this.selectedTreeEnums = [];
    this.searchedNodeKeys = [];
    this.searchedNodeKeysRecord = {};
    this.customParentNode = null;
    this.searchString = '';
  }
  @Mutation
  public setSearchString(searchStr: string): void {
    this.searchString = searchStr ?? '';
  }
  @Mutation
  public setLoadingNodesTable(loading: boolean): void {
    this.loadingNodesTable = loading;
  }
  @Mutation
  public setInMergePhase(merge: boolean): void {
    this.inMergePhase = merge;
    if (!merge) {
      this.selectedNodeKeysForMerge = [];
    }
  }
  @Mutation
  public setNodesTable(nodesTable: VariableNodesTable): void {
    this.nodesTable = nodesTable;
  }
  @Mutation
  public setRootAsset(asset: Nil<IAssignmentTreeNodeViewModel>): void {
    this.rootAsset = asset;
  }
  @Mutation
  public toggleNodeIsOpen(node: VariableTreeNode): void {
    if (this.openNodesRecord == null) {
      this.openNodesRecord = {};
    }

    const nodeExists: boolean = this.openNodesRecord[node.key] != null;
    if (nodeExists) {
      Vue.delete(this.openNodesRecord, node.key);
    } else {
      Vue.set(this.openNodesRecord, node.key, node.key);
    }
  }
  @Mutation
  public collapseAll():void {
    this.openNodesRecord = {};
  }
  @Mutation
  public selectNodes(nodes: Array<VariableTreeNode>): void {
    if (!selectedNodeIsClean(this.selectedNode, this.nodesTable)) {
      eventBus.$emit(Events.VariableTreeTriggerUnsavedChanges);
      return;
    }

    nodes.forEach(n => {
      Vue.set(this.selectedNodesRecord, n.key, n.key);
    });

    const tableKeys: string[] = Object.keys(this.selectedNodesRecord);

    if (this.selectedNode == null || this.selectedNodesRecord[this.selectedNode.key] == null) {
      this.selectedNode = deepClone(this.nodesTable[tableKeys[0]]);
    }
  }
  @Mutation
  public deselectNodes(nodes: Array<VariableTreeNode>): void {
    if (!selectedNodeIsClean(this.selectedNode, this.nodesTable)) {
      eventBus.$emit(Events.VariableTreeTriggerUnsavedChanges);
      return;
    }

    nodes.forEach(n => {
      Vue.delete(this.selectedNodesRecord, n.key);
    });

    const tableKeys: string[] = Object.keys(this.selectedNodesRecord);

    if (tableKeys.length <= 0) {
      this.selectedNode = null;
    } else if (this.selectedNode == null || this.selectedNodesRecord[this.selectedNode.key] == null) {
      this.selectedNode = deepClone(this.nodesTable[tableKeys[0]]);
    }
  }
  @Mutation
  public toggleSelectNode([node, batchNodeSelection]: [VariableTreeNode, boolean?]): void {
    if (!selectedNodeIsClean(this.selectedNode, this.nodesTable)) {
      eventBus.$emit(Events.VariableTreeTriggerUnsavedChanges);
      return;
    }

    const nodeSelected: boolean = this.selectedNodesRecord[node.key] != null;
    if (nodeSelected) {
      if (!batchNodeSelection && Object.keys(this.selectedNodesRecord).length > 1) {
        this.selectedNodesRecord = { [node.key]: node.key };
      } else {
        Vue.delete(this.selectedNodesRecord, node.key);
      }
    } else {
      if (batchNodeSelection) {
        Vue.set(this.selectedNodesRecord, node.key, node.key);
      } else {
        this.selectedNodesRecord = { [node.key]: node.key };
      }
    }

    const recordKeys: string[] = Object.keys(this.selectedNodesRecord);
    if (recordKeys.length == 0) {
      this.selectedNode = null;
    } else if (recordKeys.length === 1 && recordKeys[0] !== this.selectedNode?.key) {
      this.selectedNode = deepClone(this.nodesTable[recordKeys[0]]);
    } else if (!batchNodeSelection || this.selectedNode == null) {
      this.selectedNode = deepClone(node);
    }
  }
  @Mutation
  public setSelectedNode(node: VariableTreeNode): void {
    this.selectedNode = node != null ? deepClone(node) : null;
  }
  @Mutation
  public setCustomParentNode(node: VariableTreeNode): void {
    const nodeToSet = Object.assign({}, node);
    this.customParentNode = nodeToSet;
  }
  @Mutation
  public clearNode() {
    this.selectedNode = null;
    this.selectedNodesRecord = {};
    editor.setCurrentMode(CurrentMode.Edit);
  }

  @Mutation
  public addNodeKeyForMerge(nodeKey: string): void {
    this.selectedNodeKeysForMerge.push(nodeKey);
  }

  @Mutation
  public removeNodeKeyForMerge(nodeKey: string): void {
    this.selectedNodeKeysForMerge = this.selectedNodeKeysForMerge.filter((key) => key !== nodeKey);
  }

  @Mutation
  public setSelectedTreeFilter(value: TreeFilterEnum): void {
    this.selectedTreeEnum = value;
  }

  @Mutation
  public setSearchedNodeKeys(keys: string[]): void {
    this.searchedNodeKeysRecord = keys?.reduce((acc: Record<string, string>, k: string) => {
      acc[k] = k;
      return acc;
    }, {}) ?? {};
  }

  @Mutation
  public updateVariableNode(payload: { variable: IAssetReportVariableViewModel,
      leadingVariable?: IAssetReportVariableViewModel }): void {
    const { variable, leadingVariable } = payload;
    if (variable == null) {
      return;
    }
    const key = leadingVariable ? getUniqueVariableKey(leadingVariable) : getUniqueVariableKey(variable);
    const normalizer = new AssetTreeNodesNormalizer();
    const treeNode = normalizer.convertInputToLevelNode(variable);

    const existingNode: ITreeNode<IAssetReportVariableViewModel> = this.nodesTable[key];

    if (existingNode == null) {
      Vue.set(this.nodesTable, key, treeNode);
      return;
    }

    Object.keys(treeNode).forEach((propKey: string) => {
      type t = keyof ITreeNode<IAssetReportVariableViewModel>;
      let prop = this.nodesTable[key][propKey as t];

      if (prop != null) {
        prop = treeNode[propKey as t];
      } else {
        Vue.set(treeNode, propKey, treeNode[propKey as t]);
      }

      Vue.set(this.nodesTable[key], propKey, prop);
    });
  }
  @Mutation
  public hideMergedItem(node: string) {
    Vue.set(this.nodesTable, node, null);
  }
  @Mutation
  public setSelectedTreeFilters(filters: TreeFilterEnum[]) {
    this.selectedTreeEnums = filters;
  }

  // Actions
  @Action({ rawError: true })
  public async loadTree(rootAsset: IAssignmentTreeNodeViewModel): Promise<void> {
    this.setLoadingNodesTable(true);
    this.setInMergePhase(false);
    this.clearNode();

    try {
       const apiVersion =  ApiVersion.Two;
      this.setRootAsset(rootAsset);
      const levels = await ReportAssetHierarchyService.createDefault().getLevelsUnderAsset(rootAsset.key, apiVersion);
      if (rootAsset.key != this.rootAsset.key) {
        return;
      }
      const normalizer = new AssetTreeNodesNormalizer();
      normalizer.convertNodes(levels);
      if (normalizer.hasNodes) {
        const service = AssetsService.createDefault();
        const variables: IAssetReportVariableViewModel[] = await service.getAssetReportVariables(rootAsset.key, apiVersion);
        if (rootAsset.key != this.rootAsset.key) {
          return;
        }
        normalizer.convertVariables(variables);
      }
      const table = normalizer.processChildren().treeNodesTable;
      this.setNodesTable(table);
    } finally {
      this.setLoadingNodesTable(false);
    }
  }

  @Action({ rawError: true })
  public async openParentNodes(nodeKey: string): Promise<void> {
    let currentInputNode: VariableTreeNode = this.nodeByKey(nodeKey, this.treeHelper.allNodes);
    let parentNode: VariableTreeNode;

    do {
      if (currentInputNode.parentKey === currentInputNode.key) {
        break;
      }
      parentNode = this.nodeByKey(currentInputNode.parentKey, this.treeHelper.allNodes);
      if (parentNode) {
        parentNode.isOpen = true;
      }
      currentInputNode = parentNode;
    } while (currentInputNode);
  }

  @Action({ rawError: true })
  public async mergeSelectedNodes(newInputName: string): Promise<void> {
    if (this.selectedNodeKeysForMerge.length > 0) {
      const selectedNodes: ITreeNode<VariableTreeNodeData>[] = [];
      this.selectedNodeKeysForMerge.forEach((key) => {
        selectedNodes.push(this.nodesTable[key]);
      });

      const data: IUpdateVariableViewModel = {
        assetKey: this.rootAsset.key,
        nodeKey: selectedNodes[0].data.nodeKey,
        newDisplayName: newInputName,
        measurementType: selectedNodes[0].data.measurementType,
        dataRefs: [],
        accessSettings: [],
        mergeAction: VariableMergeActionEnum.Merge
      };

      const itemsToHide: string[] = [];
      let accessSetting: AccessSettingEnum[] = [];
      selectedNodes.forEach((selectedNode, index) => {
        data.dataRefs = data.dataRefs.concat(selectedNode.data.dataRefs);
        accessSetting = accessSetting.concat(selectedNode.data.accessSettings);
        if (index !== 0) {
          itemsToHide.push(selectedNode.data.dataRefs[0]);
        }
      });

      // hide merged items
      for (const node in this.nodesTable) {
        if (this.nodesTable.hasOwnProperty(node) && this.nodesTable[node]?.data) {
          if (itemsToHide.includes(this.nodesTable[node].data.dataRefs[0])) {
            this.hideMergedItem(node);
          }
        }
      }

      data.accessSettings = Array.from(new Set(accessSetting));
      const variable = await VariableEditorService.createDefault().saveVariable(data);
      this.updateVariableNode({ variable, leadingVariable: selectedNodes[0].data });
      this.clearNode();
      this.setInMergePhase(false);
    }
  }
}

export default getModule(InputsTreeModule, store);

function recursiveFindNode(node: any, currentNodeKey: string): VariableTreeNode {
  if (node?.key === currentNodeKey) {
    return node;
  }
  // for (let i = 0; i < node.childrenKeys?.length; i++) {
  //   const currentInputNode = recursiveFindNode(node, node.childrenKeys[i], trees);
  //   const match = recursiveFindNode(node.childrenKeys[i], currentNodeKey);
  //   if (match) {
  //     return match;
  //   }
  // }
}

function selectedNodeIsClean(selectedNode: VariableTreeNode, nodesTable: VariableNodesTable): boolean {
  if (editor.currentMode === CurrentMode.Create) {
    return false;
  }
  const originalNodeData = nodesTable[selectedNode?.key]?.data;
  const activeNodeData = selectedNode?.data;

  if (originalNodeData == null || activeNodeData == null) {
    return true;
  }
  activeNodeData.notes = normalizeNotes(originalNodeData.notes, activeNodeData.notes);

  return (
    originalNodeData.displayName === activeNodeData.displayName &&
    originalNodeData.measurementType === activeNodeData.measurementType &&
    originalNodeData.notes === activeNodeData.notes &&
    JSON.stringify(originalNodeData.accessSettings.sort()) === JSON.stringify(activeNodeData.accessSettings.sort())
  );
}
