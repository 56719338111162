


























// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import { LogicalOperatorEnum } from '@/enums/calculator-enums';
// View Models
// Components
// Services
@Component({
  components: {},
  model: {},
  name: 'calc-data-part-operator'
})
export default class CalcDataPartLogicalOperator extends Vue {
  // VUE.JS Props
  @Prop()
  public part: object[];
  // VUEX

  // Properties
  public LogicalOperatorEnum = LogicalOperatorEnum;
  // Fields

  // Getters

  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  // private mounted(): void {}
  // private beforeUpdate(): void {}
  // private updated(): void {}
  // private beforeDestroy(): void {}
  // private destroyed(): void {}

  // Helper Methods

  // Event Methods

  // Watchers

  // Emitters
}
