import { isStringEmpty } from '../string';

export function validateVariableNotesProp(notes?: string): boolean | null {
  // it is optional and if it has value, the max is 500
  return isStringEmpty(notes) ? null : notes.length <= 500;
}

export function normalizeNotes(oldNotes?: string, newNotes?: string): string | null {
  return isStringEmpty(oldNotes) && isStringEmpty(newNotes) ? oldNotes : newNotes;
}
