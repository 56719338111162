import { ITreeNode, TreeNodesTable } from '@/view-models/tree';
import {
  IAssetHierarchyNodeViewModel,
  IAssetReportVariableViewModel,
  IAssetNodeViewModel,
  VariableTreeNodeData
} from '@/view-models/variables';

export class AssetTreeNodesNormalizer {

  private childrenKeysByParents: Record<string, string[]> = {};
  public treeNodesTable: TreeNodesTable<VariableTreeNodeData> = {};

  public get hasNodes(): boolean {
    return Object.keys(this.treeNodesTable).length > 0;
  }

  public convertNodes(list: IAssetHierarchyNodeViewModel[]): this {
    this.treeNodesTable = list.reduce((acc, hierarchyNode) => {
      if (!(hierarchyNode.key in acc)) {
        acc[hierarchyNode.key] = this.convertHierarchyNodeToLevelNode(hierarchyNode);
        this.collectParentChildrenKeys(acc[hierarchyNode.key]);
        acc = (hierarchyNode?.availableNodes ?? [])
          .reduce((acc2, assetNode) => {
            acc[hierarchyNode.key].childrenKeys.push(assetNode.nodeKey);
            // This is to deal with Asset straight input node having the same key,
            // but with a suffix of Data in the name
            const isDataNode: boolean = assetNode.parentNodeKey ===  hierarchyNode.key;
            assetNode.nodeKey = isDataNode ? `${assetNode.nodeKey}-data` :  assetNode.nodeKey;
            assetNode.variablePath = `${assetNode.variablePath}`;
            acc2[assetNode.nodeKey] = this.convertNodeToLevelNode(assetNode);
            this.collectParentChildrenKeys(acc2[assetNode.nodeKey]);
            return acc2;
          }, acc);
      }

      return acc;
    }, this.treeNodesTable);

    return this;
  }

  public convertVariables(list: IAssetReportVariableViewModel[]): this {
    this.treeNodesTable = list.reduce((acc, input) => {
      const leaf = this.convertInputToLevelNode(input);
      // This is to deal with Asset straight input node having the same key, but with a suffix of Data in the name
      leaf.parentKey = `${leaf.parentKey}-data`;
      this.collectParentChildrenKeys(leaf);
      acc[leaf.key] = leaf;
      return acc;
    }, this.treeNodesTable);

    return this;
  }

  public processChildren(): this {
    for (const parentKey of Object.keys(this.childrenKeysByParents)) {
      if (parentKey in this.treeNodesTable) {
        this.treeNodesTable[parentKey].childrenKeys = this.childrenKeysByParents[parentKey];
      }
    }

    return this;
  }

  private collectParentChildrenKeys(level: ITreeNode<VariableTreeNodeData>) {
    if (level.parentKey != null && level.parentKey !== level.key) {
      if (!(level.parentKey in this.childrenKeysByParents)) {
        this.childrenKeysByParents[level.parentKey] = [];
      }
      this.childrenKeysByParents[level.parentKey].push(level.key);
    }
  }

  private convertHierarchyNodeToLevelNode(node: IAssetHierarchyNodeViewModel): ITreeNode<VariableTreeNodeData> {
    return {
      key: node.key,
      name: node.name,
      parentKey: node.parentKey,
      isOpen: false,
      show: true,
      isLeaf: false,
      isSelected: false,
      isSelectedForMerge: false,
      history: null,
      data: null,
      childrenKeys: [],
      variablePath: node.hierarchyPath
    };
  }

  private convertNodeToLevelNode(node: IAssetNodeViewModel): ITreeNode<VariableTreeNodeData> {
    return {
      key: node.nodeKey,
      name: node.nodeName,
      parentKey: node.parentNodeKey,
      isOpen: false,
      show: true,
      isLeaf: false,
      isSelected: false,
      isSelectedForMerge: false,
      history: null,
      data: null,
      // leftIconPath: require('../../assets/icons/burner/burner-24x36.svg'),
      leftIconPath: require('../../assets/icons/burner/COOLStar_3D.png'),
      childrenKeys: [],
      variablePath: node.hierarchyPath
    };
  }

  public convertInputToLevelNode(input: IAssetReportVariableViewModel): ITreeNode<VariableTreeNodeData> {
    const key = getUniqueVariableKey(input);
    const name = input.displayName ?? input.displayValues?.join(',');
    return {
      key,
      name,
      parentKey: input.nodeKey,
      isOpen: false,
      show: true,
      isLeaf: true,
      isSelected: false,
      isSelectedForMerge: false,
      history: null,
      data: input,
      variablePath: input.variablePath,
      childrenKeys: []
    };
  }
}

export function getUniqueVariableKey(input: { nodeKey: string, dataRefs: string[] }) {
  if (input.dataRefs) {
  return `${input.nodeKey}-${input.dataRefs.join('-')}`;
  }
}
