






















// Libraries
import { Nil } from "@/utils/types";
import { VariableTreeNode } from "@/view-models/variables";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
// View Models
// Components
import VisibilityObserver from '@/components/VisibilityObserver.vue';
import TreeNodeOptions from "@/components/variable-tree/TreeNodeOptions.vue";
import TreeNodeCheckbox from "@/components/variable-tree/TreeNodeCheckbox.vue";
import TextHighlight from 'vue-text-highlight';
import Tooltip from '@/components/Tooltip.vue';

// Store
import inputsTree from "@/store/inputs-tree";
import app from "@/store/app";
import eventBus from "@/utils/event-bus";

@Component({
  name: 'tree-node-flat',
  components: {
    VisibilityObserver,
    TreeNodeOptions,
    TreeNodeCheckbox,
    TextHighlight,
    Tooltip
  }
})
export default class TreeNodeFlat extends Vue {
  // VUE.JS Props
  @VModel({ required: true })
  public node!: Nil<VariableTreeNode>;
  @Prop({ default: false, type: Boolean })
  private truncatePath: boolean;
  // VUEX
  // Properties
  public visible: boolean = false;
  private pathDelimiter: string = '/';
  public isHovered: boolean = false;
  // Fields
  // Getters
  public get isSelected(): boolean {
    return inputsTree.selectedNodesRecord[this.node.key] != null;
  }
  public get queries() {
    return inputsTree.searchString.split(' ');
  }
  public get customVariableValidation(): boolean {
    return !app.currentUser.isCustomerUser;
  }
  public get variablePathNodes(): string[] {
    return this.node.data.variablePath?.split(this.pathDelimiter) ?? [];
  }
  public get variablePathDisplay(): string {
    const localVariablePathNodes: string[] = this.variablePathNodes.slice(1, this.variablePathNodes.length - 1);

    if (this.truncatePath && localVariablePathNodes.length > 0) {
      const firstNode: string = localVariablePathNodes[0];
      const lastNode: string = localVariablePathNodes.length > 1 ? localVariablePathNodes[localVariablePathNodes.length - 1] : null;
      const ellipsis: string = localVariablePathNodes.length > 2 ? '...' : null;

      return [firstNode, ellipsis, lastNode].filter(n => n != null).join(` ${this.pathDelimiter} `);
    }

    return Array.isArray(localVariablePathNodes) ? localVariablePathNodes.join(` ${this.pathDelimiter} `) : null;
  }
  public get variableName(): string {
    if (this.node.name != null) {
      return this.node.name;
    }
    
    if (this.variablePathNodes.length > 0) {
      return this.variablePathNodes[this.variablePathNodes.length - 1];
    }

    return null;
  }
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  // private mounted(): void {}
  // private beforeUpdate(): void {}
  // private updated(): void {}
  // private beforeDestroy(): void {}
  // private destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public toggleSelectNode(): void {
    if (!this.isSelected) {
      if (this.customVariableValidation) {
      eventBus.$emit("selected-node", this.node);
      }
    }
    inputsTree.toggleSelectNode([this.node, false]);
  }
  // Watchers
  // Emitters
}
