import axios, { AxiosInstance } from 'axios';
import config from '@/config';
import { BaseProvider } from '@/utils/providers/base-provider';
import { authInterceptor } from '@/utils/auth';

class HttpProvider extends BaseProvider<AxiosInstance> {
  constructor(private getBaseUrl: (() => string)) {
    super('Http Client');
  }

  public init(): void {
    this.instance = axios.create({
      baseURL: this.getBaseUrl(),
      timeout: 60000
    });
    this.instance.interceptors.request.use(authInterceptor);
  }
}

const http: HttpProvider = new HttpProvider(() => config.instance.baseApiUrl);
const umHttp: HttpProvider = new HttpProvider(() => config.instance.umApiUrl);
export { http, umHttp};

export function initializeHttpClients() {
  http.init();
  umHttp.init();
}
