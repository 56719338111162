import { DebugLogViewModel, ErrorLogViewModel } from '@/view-models/logger';
import { AxiosInstance } from 'axios';
import ControllerBaseService from './common/controller-base-service';
import { umHttp } from './common/http';

export default class LoggerService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('api/logger', axios);
  }

  public static createDefault(): LoggerService {
    return new LoggerService(umHttp.instance);
  }

  public async postError(error: ErrorLogViewModel): Promise<void> {
    try {
      await this.httpPost<void>('error', error);
    } catch (err) {
      errorHandler(err);
    }
  }

  public async postDebug(message: DebugLogViewModel): Promise<void> {
    try {
      await this.httpPost<void>('debug', message);
    } catch (err) {
      errorHandler(err);
    }
  }
}

const errorHandler = (error: any): void => {
  // eslint-disable-next-line no-console
  // console.error(error);
};
