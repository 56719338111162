













































































  import editor from '@/store/editor';
  import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import VariableEditorStore from '@/store/editor';
  import { ICopyVariableRequest, ICopyVariableResponse } from '@/view-models/variables';
  import Loading from '../common/Loading.vue';
  import error, { IErrorPayload } from '@/store/error';

@Component ({
  name: 'variable-clone-modal',
  components: {
    Loading
  },
})

export default class VariableCloneModal extends Vue {
  public saveComplete: boolean = false;
  public isSaving: boolean = false;
  public copyResults: {response: ICopyVariableResponse, destinationKey: string}[] = [];
  public copyCustomResults: {response: ICopyVariableResponse, destinationKey: string}[] = [];

  public get assets(): IAssignmentTreeNodeViewModel[] {
    return editor.assets.filter((asset) => asset.key !== this.currentAsset.key);
  }
  public get currentAsset(): IAssignmentTreeNodeViewModel {
    return editor.currentAsset;
  }

  public destinationAssets: string[] = [];

  public get selectedAssets(): string[] {
    return this.destinationAssets;
  }

  public updateDestination(value: boolean, key: string) {
    const index = this.destinationAssets.findIndex(((item) => item === key));
    this.isSaving = false;
    if (index >= 0) {
      this.destinationAssets.splice(index, 1);
    } else {
      this.destinationAssets.push(key);
    }
  }
  public visible: boolean = false;

  public async cloneVariable() {
    this.copyResults = [];
    const cloneRequestPromises: Promise<any>[] = [];
    const clonedCustomVariables: Promise<any>[] = [];
    this.selectedAssets.forEach((key) => {
      const request = {
        sourceAssetKey: this.currentAsset.key,
        destinationAssetKey: key
      } as ICopyVariableRequest;

      cloneRequestPromises.push(VariableEditorStore.copyVariables(request));
      clonedCustomVariables.push(VariableEditorStore.copyCustomVariables(request));
    });

    try {
      this.isSaving = true;
      this.saveComplete = false;
      const variableResults = await Promise.allSettled(cloneRequestPromises);
      const customVariableResults = await Promise.allSettled(clonedCustomVariables);

      variableResults.forEach((item) => {
        this.copyResults.push({response: (item as any)?.value?.data,
          destinationKey: JSON.parse((item as any)?.value?.config.data).destinationAssetKey});
      });

      customVariableResults.forEach((item) => {
        this.copyCustomResults.push({response: (item as any)?.value?.data,
          destinationKey: JSON.parse((item as any)?.value?.config.data).destinationAssetKey});
      });
      this.saveComplete = true;
    } catch (error) {
      this.$bvToast.show('custom-variable-clone-error');
     }
  }

  public get errorStore() {
    return error;
  }
  public getNameFromSite(siteKey: string) {
    const site = editor.sites.find((s) => s.key === siteKey);
    return site.name;
  }

  public getNameFromAsset(assetKey: string) {
    const asset = editor.assets.find((a) => a.key === assetKey);
    return asset.name;
  }

  public show(): void {
    this.visible = true;
  }
  public close(): void {
    this.destinationAssets = [];
    this.visible = false;
    this.copyCustomResults = [];
    this.copyResults = [];
    this.saveComplete = false;
    this.isSaving = false;
  }
}
