






































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import errorStore from '@/store/error';

Vue.use(BootstrapVue);

@Component({
  name: 'error'
})
export default class Error extends Vue {
  get error() {
    return errorStore.error;
  }

  get show() {
    return errorStore.showModal;
  }

  private onClose() {
    // Handle Error
    if (errorStore.routeHomeAfterError != null) {
      this.$router.push('/');
    }
    // Clear Error
    errorStore.clearError();
  }

}
