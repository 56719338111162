
































































































import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component ({
    name: 'save-confirmation-modal'
})

export default class SaveConfirmationModal extends Vue {
      // VUE.JS Props
    @Prop({ required: true })
    public modalTitle!: string;

    @Prop({ required: true })
    public modalContent!: string;
    
    @Prop({ required: false, default:'' })
    public modalSecondaryContent!: string;
    
    @Prop({ required: true })
    public isWarning!: boolean;

    @Prop({ required: true })
    public isNameMissing!: boolean;

    @Prop({ required: true })
    public isInvalidFormula!: boolean;

    @Prop({ required: true })
    public isDeleteActive!: boolean;

    @Prop({ required: true })
    public isFormulaMissing!: boolean;
    

  // Properties
    public showModal = true;

    // Event Methods
    @Emit('save')
    public saveVariable(): void {
        this.showModal = false;
    }

    public closeConfirmationModal(): void {
      this.showModal = false;
    }

    public showModalPopup() { 
      this.showModal = true;
    }

    public deactivate() {
      this.$destroy();
    }
    @Emit('add-formula')
    public addFormula(): void {
      this.showModal = false;
    }
    @Emit('add-name')
    public addName(): void {
      this.showModal = false;
    }
    @Emit('delete-variable')
    public deleteVariable():void{
      this.showModal=false;
    }
}
