import Vue from 'vue';
import { Component , Watch } from 'vue-property-decorator';
import appStore from '@/store/app';

@Component
export default class AppReady extends Vue {
  public get $isAppReady(): boolean {
    return appStore.isAppInitialized;
  }

  private alreadyCalled: boolean = false;

  public mounted(): void {
    this.triggerReady();
  }

  // override-able
  // this is called when the app has been initialized
  public ready(): void {}

  private triggerReady() {
    if (this.$isAppReady && !this.alreadyCalled) {
      this.alreadyCalled = true;
      this.ready();
    }
  }

  @Watch('$isAppReady')
  private $isAppReadyChanged() {
    this.triggerReady();
  }
}
