













































































































































































































































































// Libraries
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/utils/event-bus";
// import HelperMethods from '@/shared/helper-methods';
import {
  DataPartEnum,
  OperatorEnum,
  LogicalOperatorEnum,
  FunctionEnum,
} from "@/enums/calculator-enums";

// View Models
import CalculatorFormula from "@/components/calculator/sub-components/Formula.vue";
import {
  Formula,
  Constant,
  DataPart,
  Operator,
  LogicalOperator,
  LeftParenthesis,
  RightParenthesis,
  Variable,
  Function,
  LogicStatement,
} from "@/components/calculator/classes";
import { IFormula, IKeyPair } from "@/view-models/calculator-view-models";
import { EvaluateFormulaRequest } from "@/services/variable-editor-service";
// Components
// Stores
import VariableEditorStore from "@/store/editor";
import HelperMethods from "@/utils/helper-methods";
import editor from "@/store/editor";
import { VariableTreeNode } from "@/view-models/variables";
import { CurrentMode } from "@/enums/current-mode";
import inputsTree from "@/store/inputs-tree";

@Component({
  components: {
    CalculatorFormula,
  },
  name: "calculator",
})
export default class Calculator extends Vue {
  // VUE.JS Props
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ default: "" })
  public value!: any;

  // VUEX
  // Properties
  public OperatorEnum = OperatorEnum;
  public LogicalOperatorEnum = LogicalOperatorEnum;
  public FunctionEnum = FunctionEnum;
  public DataPartEnum = DataPartEnum;
  public formulas: Array<Formula> = [new Formula()];
  public currentFocus: DataPart | any =
    this.currentFormula.data[this.currentFormula.data.length - 1];
  public showInfo = false;
  // Fields
  private functionsAndTimeCollapsed: boolean = false;
  private displayHasScrollbar: boolean = false;
  public hasError: boolean = false;
  private errorMessage: string = "";
  private errorMessageIn: boolean = false;
  private isProcessing: boolean = false;
  public currentModeEnum = CurrentMode;

  //Stores
  public variableEditorStore = VariableEditorStore;

  // Getters
  get currentFormula(): Formula | any {
    return this.formulas[this.formulas.length - 1];
  }

  get currentMode(): string {
    return this.variableEditorStore.currentMode;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  private created(): void {
    document.body.addEventListener("click", this.handleClick);
    document.body.addEventListener("keydown", this.handleKeydown);
    document.body.addEventListener("keypress", this.handleKeypress);
  }
  @Watch("value", { immediate: true })
  public valueChanged(node: any) {
    if (this.value?.data?.formula) {
      this.copyFormula(node);
    }
  }
  // beforeMount(): void {}
  private mounted(): void {
    EventBus.$on("selected-node", this.valueChanged);
    EventBus.$on("update-formula", this.processFormula);
    EventBus.$on("calculator-clear-error", () => {
      this.hasError = false;
    });
    EventBus.$on("calculator-notify-focus", this.focusEvent);
    EventBus.$on("calculator-clear-formula", this.clearFormula);
    EventBus.$on("calculator-copy-formula", this.copyFormula);
    EventBus.$on("calculator-insert-variable", this.insertVariableToFormula);
    EventBus.$on("calculator-clear-focus", this.changeFocus);
    EventBus.$on("calculator-show-focus", this.focus);
    this.$nextTick(() => setTimeout(() => this.focus(), 0));
    this.scrollDown();
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  private beforeDestroy(): void {
    document.body.removeEventListener("click", this.handleClick);
    document.body.removeEventListener("keydown", this.handleKeydown);
    document.body.removeEventListener("keypress", this.handleKeypress);

    EventBus.$off('calculator-notify-focus', this.focusEvent);
    EventBus.$off('calculator-clear-formula', this.clearFormula);
    EventBus.$off('calculator-copy-formula', this.copyFormula);
    EventBus.$off('calculator-insert-variable', this.insertVariableToFormula);
    EventBus.$off('update-formula', this.processFormula);
    EventBus.$off('selected-node', this.valueChanged);
    EventBus.$off('calculator-clear-focus', this.changeFocus);

    EventBus.$off("calculator-clear-error", () => {
      this.hasError = false;
    });
  }
  // destroyed(): void {}

  // Helper Methods
  private buildFormula(parsedData: IFormula, isCopy: boolean = false): Formula {
    const f = new Formula();
    if (parsedData) {
    f.build(parsedData, isCopy);
    }
    return f;
  }

  public changeFocus() {
    this.currentFocus = null;
  }

  public async processFormula(): Promise<boolean> {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.hasError = false;
      const formula = this.buildFormula(this.currentFormula, true);
      const preppedFormula = formula.prep();

      if (this.currentFormula.isValid) {
       const formattedFormula = this.getJsonCompatibleFormula(preppedFormula);
        const evaluateFormulaRequest = new EvaluateFormulaRequest();
        evaluateFormulaRequest.assetKey = editor.currentAsset.key;
        evaluateFormulaRequest.formulaJson = JSON.stringify(formattedFormula);
        const formulaJson = JSON.stringify(formattedFormula);
        editor.setFormulaJson(formulaJson);
        if (!editor.originalFormulaJson) {
          editor.setOriginalFormulaJson(formulaJson);
        }

        try {
          let result = await this.variableEditorStore.evaluateFormula(
            evaluateFormulaRequest
          );
          result = result.data;
          this.isProcessing = false;
          this.currentFormula.answer = result;
          this.formulas.push(new Formula());
          this.scrollDown();
          this.$nextTick(() => {
            setTimeout(
              () =>
                HelperMethods.runAnimation(
                  "slide-up",
                  this.$refs.displayContent as HTMLElement
                ),
              0
            );
          });
          return true;
        } catch (err) {
          if (err.response.status == 400) {
            this.showErrorMessage(err.response.data.toString());
          } else {
          this.showErrorMessage(this.$t("calculator.apiError").toString());
          }
          EventBus.$emit('updateMsg', { isError: true, message: err.response.data.toString() });
          this.isProcessing = false;
          return false;
        }
      } else {
        this.isProcessing = false;
        return false;
      }
    }
    return false;
  }

  private scrollDown() {
    this.$nextTick(() => {
      setTimeout(
        () =>
          ((this.$refs?.display as HTMLElement).scrollTop = (
            this.$refs?.displayContent as HTMLElement
          )?.offsetHeight),
        0
      );
    });
  }

  private showErrorMessage(message: string) {
    this.isProcessing = false;
    this.hasError = true;
    this.errorMessage = message;
    this.showTemporaryErrorMessage();
  }

  private showTemporaryErrorMessage() {
    this.errorMessageIn = false;

    this.$nextTick(() => {
      setTimeout(() => {
        this.errorMessageIn = true;

        setTimeout(() => (this.errorMessageIn = false), 3000);
      }, 600);
    });
  }

  private addConstant(value: string) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      if (/^(.|-|%)$/.test(value) || !isNaN(Number.parseFloat(value))) {
        if (this.currentFocus.type === DataPartEnum.Constant) {
          (this.currentFocus as Constant).update(value);
        } else if (value !== "%") {
          this.currentFocus.parent.createPart(
            new Constant(value),
            this.currentFocus
          );
        }
      }
    }
  }

  public addFunction(value: FunctionEnum) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      const newObj = new Function(value);
      this.currentFocus.parent.createPart(newObj, this.currentFocus);
      this.$nextTick(() => setTimeout(() => this.focusEvent(newObj.variables[0]), 0));
    }
  }

  private addLogicStatement() {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      const newObj = new LogicStatement();
      this.currentFocus.parent.createPart(newObj, this.currentFocus);
      this.$nextTick(() => setTimeout(() => this.focusEvent(newObj.if[0]), 0));
    }
  }

  private addOperator(value: OperatorEnum) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      switch (value) {
        case OperatorEnum.Square:
          // Necessary in order for backend to treat like power type
          value = OperatorEnum.Power;
          this.currentFocus.parent.createPart(
            new Operator(value),
            this.currentFocus
          );

          this.$nextTick(() => {
            this.currentFocus.parent.createPart(
              new Constant("2"),
              this.currentFocus
            );
          });

          break;

        case OperatorEnum.SquareRoot:
          this.currentFocus.parent.createPart(
            new Operator(value),
            this.currentFocus
          );

          break;

        case OperatorEnum.Power:
          this.addParenthesis(')', true);
          this.addParenthesis('(');
          this.currentFocus.parent.createPart(
            new Operator(value),
            this.currentFocus
          );
          break;

        default:
          this.currentFocus.parent.createPart(
            new Operator(value),
            this.currentFocus
          );

          break;
      }
    }
  }

  private addLogicalOperator(value: LogicalOperatorEnum) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      const parentArrayName = Object.keys(this.currentFocus.parent)
        .map((k) =>
          this.currentFocus.parent[k] === this.currentFocus.parentArray
            ? k
            : null
        )
        .find((k) => k !== null);
      if (parentArrayName && parentArrayName === "if") {
        this.currentFocus.parent.createPart(
          new LogicalOperator(value),
          this.currentFocus
        );
      }
    }
  }

  private addParenthesis(value: string, flag?: boolean) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      if (value === "(") {
        this.currentFocus.parent.createPart(
          new LeftParenthesis(),
          this.currentFocus
        );
      } else if (value === ")") {
        this.currentFocus.parent.createPart(
          new RightParenthesis(),
          this.currentFocus
        );
        if (flag) {
          this.$nextTick(() => setTimeout(() => this.focusEvent(this.currentFocus.parent.data[this.currentFocus.parent.data.length - 2])));
        }
      }
    }
  }

  private addVariable(key: string, displayName: string) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      this.currentFocus.parent.createPart(
        new Variable(key, displayName),
        this.currentFocus
      );
    }
  }

  private deleteDataPart(forwardDelete: boolean) {
    if (!this.isProcessing) {
      if (this.currentFocus === null) {
        this.focus();
      }
      if (forwardDelete && this.currentFocus.parentArray) {
        if (
          this.currentFocus.parentArray.indexOf(this.currentFocus) !==
          this.currentFocus.parentArray.length - 1
        ) {
          this.shiftFocus(true);
          this.$nextTick(() => {
            this.currentFocus.parent.deletePart(this.currentFocus);
          });
        }
      } else if (this.currentFocus.type === DataPartEnum.Empty) {
        this.shiftFocus(false, true);
      } else if (
        this.currentFocus.type === DataPartEnum.Constant &&
        !this.currentFocus.delete()
      ) {
        (this.currentFocus as Constant).update();
      } else if (this.currentFocus.delete()) {
        this.currentFocus.parent.deletePart(this.currentFocus);
      }
    }
  }

  private focus() {
    const focusItem =
      this.formulas[this.formulas.length - 1].data[
        this.currentFormula.data.length - 1
      ];
    if (focusItem) {
      this.focusEvent(focusItem);
    }
  }

  private shiftFocus(moveForward: boolean, isDelete: boolean = false) {
    if (!this.isProcessing) {
      const currentIndex = this.currentFocus.parentArray
        ? this.currentFocus.parentArray.indexOf(this.currentFocus)
        : -1;
      let focusItem;

      if (currentIndex > -1) {
        if (moveForward) {
          if (currentIndex !== this.currentFocus.parentArray.length - 1) {
            focusItem = this.currentFocus.parentArray[currentIndex + 1];
          }
        } else if (currentIndex === 0) {
          if (
            (this.currentFocus.parent as DataPart).type !==
              DataPartEnum.Formula &&
            isDelete
          ) {
            focusItem = this.currentFocus.parent;
          }
        } else {
          focusItem = this.currentFocus.parentArray[currentIndex - 1];
        }
      } else {
        focusItem = this.currentFocus.parentArray
          ? this.currentFocus.parentArray[
              this.currentFocus.parentArray.length - 1
            ]
          : null;
      }

      if (focusItem) {
        this.focusEvent(focusItem);
      }
    }
  }

  private tabFocus() {
    if (!this.isProcessing) {
      const type: DataPartEnum = this.currentFocus.parent.type;
      let logicBuckets;

      switch (type) {
        case DataPartEnum.LogicStatement:
          const logicParent: LogicStatement = this.currentFocus
            .parent as LogicStatement;
          logicBuckets = [logicParent.if, logicParent.then, logicParent.else];
          break;
        case DataPartEnum.Function:
          const functionParent: Function = this.currentFocus
            .parent as Function;
          switch (functionParent.function) {
            default:
              logicBuckets = [functionParent.variables];
              break;
          }
          break;
      }

      const index = logicBuckets.indexOf(this.currentFocus.parentArray);
      const newIndex = index === logicBuckets.length - 1 ? 0 : index + 1;

      this.focusEvent(
        logicBuckets[newIndex][logicBuckets[newIndex].length - 1]
      );
    }
  }

  private clearFormula() {
    if (!this.isProcessing) {
      this.hasError = false;
      this.currentFormula.deleteAll();
    }
  }

  private clearAllFormulas() {
    if (!this.isProcessing) {
      this.hasError = false;
      this.formulas = [new Formula()];

      this.currentFocus =
        this.formulas[this.formulas.length - 1].data[
          this.currentFormula.data.length - 1
        ];
         editor.setFormulaJson(null);
    }
  }

  private recallLastAnswer() {
    if (!this.isProcessing) {
      if (this.formulas.length > 1) {
        const lastFormulaAnswer =
          this.formulas[this.formulas.length - 2].answer;

        if (lastFormulaAnswer) {
          if (
            lastFormulaAnswer.toString().toLowerCase().includes("infinity") ||
            lastFormulaAnswer.toString().toLowerCase().includes("nan")
          ) {
            this.showErrorMessage(
              this.$t("calculator.cannotReproduceAnswer").toString()
            );
          } else {
            this.currentFocus.parent.createPart(
              new Constant(lastFormulaAnswer),
              this.currentFocus
            );
          }
        }
      }
    }
  }

  private recallLastFormula() {
    if (!this.isProcessing) {
      if (this.formulas.length > 1) {
        const lastFormula = this.formulas[this.formulas.length - 2];
        const newFormulaFromLast = this.buildFormula(lastFormula, true);

        if (lastFormula.data) {
          this.currentFormula.data = newFormulaFromLast.data;
        }
      }
    }
  }

  private focusEvent(dataPart: any) {
    // check if the optional parameter causes any logic to fail
    // double check logic
    this.currentFocus =
      dataPart || this.currentFormula[this.currentFormula.data.length - 1];

    EventBus.$emit("calculator-focused-element", this.currentFocus);
  }

  private getJsonCompatibleFormula(preppedFormula: any): object {
    const isArray = Array.isArray(preppedFormula);
    // if (preppedFormula && preppedFormula.data) {
    // for (let i = 0; i< preppedFormula.data.length; i++) {
    //      if (preppedFormula.data[i].type === 'Function') {
    //        delete preppedFormula.data[i].variable;
    //      }
    // }
    // }
    for (const key in preppedFormula) {
      const value = preppedFormula[key];
      let newKey = key;
      if (!isArray) {
        // if it is an object
        delete preppedFormula[key]; // firstly remove the key
        newKey = key.charAt(0).toUpperCase() + key.substring(1); // secondly generate new key (capitalized)
      }
      let newValue = value;
      if (typeof value === "object") {
        // if it is an object, recursively capitalize it
        newValue = this.getJsonCompatibleFormula(value);
      }
      preppedFormula[newKey] = newValue;
    }
    return preppedFormula;
  }

  // Event Methods
  private copyFormula(node: VariableTreeNode) {
      const builtFormula = this.buildFormula(node.data.formula, true);
      if (builtFormula.data) {
        this.currentFormula.data = builtFormula.data;
      }
  }

  private insertVariableToFormula(
    variableKeyName: IKeyPair<string, string>
  ): void {
    if (variableKeyName !== null && variableKeyName.key !== inputsTree.selectedNode?.key) {
      this.addVariable(variableKeyName.key, variableKeyName.value);
    }
  }

  private handleClick(): void {
    EventBus.$emit("calculator-focused-element", this.currentFocus);
  }

  private handleKeydown(event: KeyboardEvent): void {
    if (this.currentFocus == null) {
      return;
    }
    switch (event.key) {
      case "Backspace":
        case "Delete":
        event.preventDefault();
        this.deleteDataPart(false);
        break;
      case "ArrowLeft":
        this.shiftFocus(false);
        break;
      case "ArrowRight":
        this.shiftFocus(true);
        break;
      case "Tab":
        this.tabFocus();
        break;
    }
  }

  private handleKeypress(event: KeyboardEvent): void {
    if (this.currentFocus == null) {
      return;
    }
    event.preventDefault();

    switch (event.key) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case ".":
      case "%":
        this.addConstant(event.key);
        break;
      case "+":
        this.addOperator(OperatorEnum.Plus);
        break;
      case "-":
        this.addOperator(OperatorEnum.Minus);
        break;
      case "*":
        this.addOperator(OperatorEnum.Multiply);
        break;
      case "/":
        this.addOperator(OperatorEnum.Divide);
        break;
      case "<":
        this.addLogicalOperator(LogicalOperatorEnum.LessThan);
        break;
      case ">":
        this.addLogicalOperator(LogicalOperatorEnum.GreaterThan);
        break;
      case "=":
        this.addLogicalOperator(LogicalOperatorEnum.Equals);
        break;
      case "(":
      case ")":
        this.addParenthesis(event.key);
        break;
      case "^": this.addOperator(OperatorEnum.Power);
        break;
      case "Enter":
        this.processFormula();
        break;
      default:
        break;
    }
  }

  public showInfoTooltip(): void {
    this.showInfo = true;
  }

  public hideInfoTooltip(): void {
    this.showInfo = false;
  }

  public deactivate() {
    this.$destroy;
  }

  // Watchers
  // Emitters
}
