export enum CountDirectionEnum {
  Up = 'Up',
  Down = 'Down'
}

export enum DataPartEnum {
  Constant = 'Constant',
  Empty = 'Empty',
  Formula = 'Formula',
  Function = 'Function',
  LogicStatement = 'LogicStatement',
  LogicalOperator = 'LogicalOperator',
  LeftParenthesis = 'LeftParenthesis',
  Operator = 'Operator',
  RightParenthesis = 'RightParenthesis',
  Time = 'Time',
  Variable = 'Variable'
}

export enum FunctionEnum {
  Average = 'Average',
  Cumulative = 'Cumulative',
  Count = 'Count',
  Lag = 'Lag',
  Max = 'Max',
  Median = 'Median',
  Min = 'Min',
  Rolling = 'Rolling',
  Shift = 'Shift',
  StandardDeviation = 'StandardDeviation',
  Sum = 'Sum',
  WeightedAverage = 'WeightedAverage'
}

export enum LogicalOperatorEnum {
  And = 'And',
  Equals = 'Equals',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  NotEqual = 'NotEqual',
  Or = 'Or'
}

export enum OperatorEnum {
  Absolute = 'Absolute',
  Divide = 'Divide',
  Logarithm = 'Logarithm',
  Minus = 'Minus',
  Multiply = 'Multiply',
  NaturalLogarithm = 'NaturalLogarithm',
  Plus = 'Plus',
  Power = 'Power',
  Root = 'Root',
  Round = 'Round',
  Square = 'Square',
  SquareRoot = 'SquareRoot'
}

export enum TimeEnum {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  Weekday = 'Weekday',
  Year = 'Year'
}

export enum StepStatusEnum {
  Action = 'Action',
  Error = 'Error',
  Ok = 'Ok',
  Processing = 'Processing'
}
