







// Libraries
import { Vue, Component, Prop } from "vue-property-decorator";
// View Models
// Components
// Services
@Component({
  components: {},
  model: {},
  name: "calc-data-part-variable",
})
export default class CalcDataPartVariable extends Vue {
  // VUE.JS Props
  @Prop()
  public part: any;
  @Prop()
  public index: number;
  // VUEX

  // Properties
  // Fields

  // Getters
  public get firstPath() {
    return this.part?.variableName.split("/")[1];
  }

  public get finalPath() {
    let vName = this.part?.variableName.split("/");
    return vName[vName.length - 2];
  }

  public get variableName() {
    let vName = this.part?.variableName.split("/");
    return vName[vName.length - 1];
  }

  public get fullPath() {
    let vName = this.part?.variableName.split("/");
    vName.splice(vName.length -1, 1);
    return vName.join("/");
  }
 public get variableLength() {
   return this.part?.variableName.split("/").length;
 }
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  // private beforeUpdate(): void {}
  // private updated(): void {}
  // private beforeDestroy(): void {}
  // private destroyed(): void {}

  // Helper Methods

  // Event Methods

  // Watchers

  // Emitters
}
