export function numberValueOutput(value: number, showFull?: boolean): string {
  if (value == null || isNaN(value)) {
    return null;
  }

  let absValue: number = Math.abs(value);

  if (value === 0 || absValue < 0.000001) {
    return `${value}`;
  }

  const multiplier: number = 10 ** getDecimalNum(absValue);
  absValue = parseFloat((absValue * multiplier).toFixed(11));
  const newValue: number = Math.round(absValue) / multiplier;
  const posNeg: number = value < 0 ? -1 : 1;

  interface NumberFormat extends Intl.NumberFormatOptions {
    notation: 'standard' | 'scientific' | 'engineering' | 'compact',
    maximumFractionDigits: number
  }

  const numberOptions: NumberFormat = {
    notation: 'compact',
    maximumFractionDigits: 1
  };

  // if larger than 1,000,000 - use short form
  if (value >= 1000000 && !showFull) {
    return newValue < 1 ? `${newValue * posNeg}` : new Intl.NumberFormat('en-US', numberOptions).format(newValue * posNeg);
  }

  return newValue < 1 ? `${newValue * posNeg}` : new Intl.NumberFormat().format(newValue * posNeg);
}

export function getDecimalNum(absValue: number) {
  let numOfDecimals: number = 2;

  if (absValue < 1) {
    if (absValue < 0.1) {
      const indexOfNonZero: number = `${absValue}`.split('').findIndex(v => parseInt(v) > 0);

      if (indexOfNonZero !== -1) {
        numOfDecimals = indexOfNonZero + 1;
      }
    } else {
      numOfDecimals = 4;
    }
  }
  return numOfDecimals;
}