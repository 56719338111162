import { Nil } from './types';

export function ensureTrailing(val: string, trail: string) {
  return val != null && !val.endsWith(trail) ? `${val}${trail}` : val;
}

export function isStringEmpty(value: Nil<string>) {
  return value == null || value === '';
}
function hexDec4Chars() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
const s4 = hexDec4Chars;
/**
 * Generates a random guid string
 * @returns {string}
 */
export function newGuid(): string {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function hashString(str: string, asHex: boolean = false): string {
  /* jshint bitwise:false */
  let i: number;
  let l: number;
  let hexValue: number = 0x811C9DC5;

  for (i = 0, l = str.length; i < l; i++) {
    hexValue ^= str.charCodeAt(i);
    hexValue += (hexValue << 1) + (hexValue << 4) + (hexValue << 7) + (hexValue << 8) + (hexValue << 24);
  }
  if (asHex) {
    // Convert to 8 digit hex string
    return ('0000000' + (hexValue >>> 0).toString(16)).substr(-8);
  }
  return (hexValue >>> 0).toString();
}
