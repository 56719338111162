// Libraries
import { Component, Vue } from 'vue-property-decorator';
// View Models
// Components

@Component
export default class ValidateMixin extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public veeValidateCtx({ dirty, validated, valid = null }: Record<string, any>) {
    return dirty || validated ? valid : null;
  }
  // Event Methods
  // Watchers
  // Emitters
}
