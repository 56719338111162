import { AxiosInstance } from 'axios';
import ControllerBaseService from './common/controller-base-service';
import { umHttp } from './common/http';

export class UserService extends ControllerBaseService {

    constructor(axios: AxiosInstance) {
        super('users', axios);
    }

    public static factory(): UserService {
        return new UserService(umHttp.instance);
    }

    public async getUserPermissions(): Promise<string[]> {
        return this.httpGet<string[]>('userPermission');
    }

    public async getCurrentUser(): Promise<any> {
        return this.httpGet<any>('current');
    }
}
