



// Libraries
import { Vue, VModel, Component, Watch } from 'vue-property-decorator';
// View Models
import { VariableTreeNode } from '@/view-models/variables';
import { CurrentMode } from '@/enums/current-mode';
import { Nil } from '@/utils/types';
// Store
import inputsTree from "@/store/inputs-tree";
import editor from "@/store/editor";

export enum CheckboxState {
  Unchecked = 'Unchecked',
  Checked = 'Checked',
  Indeterminate = 'Indeterminate'
}

@Component({
  name: 'tree-node-checkbox'
})
export default class TreeNodeCheckbox extends Vue {
  // VUE.JS Props
  @VModel({ required: true })
  public node!: Nil<VariableTreeNode>;
  // VUEX
  // Properties
  public CheckboxState = CheckboxState;
  public state: CheckboxState = CheckboxState.Unchecked;
  public filteredleafDescendents: VariableTreeNode[] = [];
  // Fields
  // Getters
  public get selectedNodesRecord(): Record<string, string> {
    return inputsTree.selectedNodesRecord;
  }
  public get allNodes(): VariableTreeNode[] {
    return inputsTree.treeHelper.allNodes;
  }
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  private created(): void {
    this.filteredleafDescendents = !this.node.isLeaf ? this.collectFilteredLeaves(this.node) : [];
  }
  // private beforeMount(): void {}
  // private mounted(): void {}
  // private beforeUpdate(): void {}
  // private updated(): void {}
  // private beforeDestroy(): void {}
  // private destroyed(): void {}
  // Private Methods
  // Helper Methods
  private collectFilteredLeaves(parentNode: VariableTreeNode, leaves: Array<VariableTreeNode> = []): Array<VariableTreeNode> {
    const parents: Array<VariableTreeNode> = [];

    if (Array.isArray(parentNode?.childrenKeys)) {
      parentNode.childrenKeys.map(k => inputsTree.filteredNodesTable[k]).forEach(c => {
        if (c.isLeaf) {
          leaves.push(c);
        } else {
          parents.push(c);
        }
      });
    }

    parents.forEach(p => this.collectFilteredLeaves(p, leaves));

    return leaves;
  }
  // Event Methods
  public onCheckboxSelect() {
    if (this.node.isLeaf) {
      inputsTree.toggleSelectNode([this.node, true]);
    } else {
      const sortedLeaves: Array<VariableTreeNode> = this.filteredleafDescendents
        .sort((a, b) => a.data?.variablePath?.localeCompare(b.data?.variablePath));
      if (this.state === CheckboxState.Checked || this.state === CheckboxState.Indeterminate) {
        inputsTree.deselectNodes(sortedLeaves);
      } else {
        inputsTree.selectNodes(sortedLeaves);
      }
    }
  }
  // Watchers
  @Watch('selectedNodesRecord', { immediate: true, deep: true })
  private selectedNodesRecordChanged(incomingTable: Record<string, string>): void {
    if (this.node.isLeaf) {
      if (editor.currentMode !== CurrentMode.Edit) {
        this.state = CheckboxState.Unchecked;
      } else {
        this.state = incomingTable[this.node.key] != null ? CheckboxState.Checked : CheckboxState.Unchecked;
      }
    } else {
      this.$nextTick(() => {
        const checkedDescendentKeys: string[] = [];

        this.filteredleafDescendents.forEach(d => {
          if (incomingTable[d.key] != null) {
            checkedDescendentKeys.push(d.key);
          }
        });

        if (this.filteredleafDescendents.length > 0 && this.filteredleafDescendents.length  <= checkedDescendentKeys.length) {
          this.state = CheckboxState.Checked;
        } else if (this.filteredleafDescendents.length > 0 && checkedDescendentKeys.length > 0) {
          this.state = CheckboxState.Indeterminate;
        } else {
          this.state = CheckboxState.Unchecked;
        }
      });
    }
  }
  // Emitters
}
