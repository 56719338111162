export default {
  'en-US': {
    time: {
      hour: '2-digit',
      minute: '2-digit'
    },
    date: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    dateOnly: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    year: {
      year: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    month: {
      month: 'long'
    },
    yearMonthDay: {
      year: '2-digit',
      month: 'short',
      day: '2-digit'
    },
    monthDay: {
      month: 'short',
      day: '2-digit'
    },
    dateShortened: {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    dateShortenedWithYear: {
      year: '2-digit',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    numericDateShortened: {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric'
    }
  },
  'fr-FR': {
    time: {
      hour: '2-digit',
      minute: '2-digit'
    },
    date: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    dateOnly: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    year: {
      year: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    month: {
      month: 'long'
    },
    yearMonthDay: {
      year: '2-digit',
      month: 'short',
      day: '2-digit'
    },
    monthDay: {
      month: 'short',
      day: '2-digit'
    },
    dateShortened: {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  },
  'en-GB': {
    time: {
      hour: '2-digit',
      minute: '2-digit'
    },
    date: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    dateOnly: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    year: {
      year: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    month: {
      month: 'long'
    },
    yearMonthDay: {
      year: '2-digit',
      month: 'short',
      day: '2-digit'
    },
    monthDay: {
      month: 'short',
      day: '2-digit'
    },
    dateShortened: {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  },
  'en-IE': {
    time: {
      hour: '2-digit',
      minute: '2-digit'
    },
    date: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    dateOnly: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    year: {
      year: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    month: {
      month: 'long'
    },
    yearMonthDay: {
      year: '2-digit',
      month: 'short',
      day: '2-digit'
    },
    monthDay: {
      month: 'short',
      day: '2-digit'
    },
    dateShortened: {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  }
};
