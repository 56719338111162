export class BaseProvider<T> {
  protected internalInstance!: T;
  protected readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  get ready(): boolean {
    return this.internalInstance != null;
  }

  get instance(): T {
    if (!this.ready) {
      throw new Error(`${this.name} instance is not ready`);
    }
    return this.internalInstance;
  }

  set instance(newOne: T) {
    this.internalInstance = newOne;
  }
}
