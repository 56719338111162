
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '.';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import AssignmentTreeService from '@/services/assignment-tree-service';
import { IUpdateVariableViewModel, IAssetReportVariableViewModel, IDeleteVariableViewModel,
  IVariablesLatestDataRequestViewModel, IVariableDataResponseViewModel, ICopyVariableRequest,
} from '@/view-models/variables';
import VariableEditorService from '@/services/variable-editor-service';
import { CurrentMode } from '@/enums/current-mode';
import { ApiVersion } from '@/enums/api-version';
import { EntityType } from '@/view-models/global-view-model';
import error from './error';

export interface IEditorModule {
  // State
  sites: IAssignmentTreeNodeViewModel[];
  assets: IAssignmentTreeNodeViewModel[];
  currentSite: IAssignmentTreeNodeViewModel;
  currentAsset: IAssignmentTreeNodeViewModel;
  isCurrentlySaving: boolean;
  currentMode: string;
  // Getters
  // Mutations
  setSites(sites: IAssignmentTreeNodeViewModel[]): void;
  setAssets(assets: []): void;
  setSite(site: IAssignmentTreeNodeViewModel): void;
  setAsset(asset: IAssignmentTreeNodeViewModel): void;
  setIsCurrentlySaving(value: boolean): void;
  setCurrentMode(value: string): void;
  // Actions
  prepareSitesAndAssets(): Promise<void>;
  saveEdits(payload: IUpdateVariableViewModel): Promise<IAssetReportVariableViewModel>;
}

@Module({ dynamic: true, store, name: 'editor' })
export class EditorModule extends VuexModule implements IEditorModule {
  // State
  public sites: IAssignmentTreeNodeViewModel[] = [];
  public assets: IAssignmentTreeNodeViewModel[] = [];
  public currentSite: IAssignmentTreeNodeViewModel = null;
  public currentAsset: IAssignmentTreeNodeViewModel = null;
  public isCurrentlySaving: boolean = false;
  public formulaJson: string = '';
  public originalFormulaJson: string = '';
  public currentMode: string = CurrentMode.Edit;
  public isLoadingSitesAndAssets: boolean = false;
  public errorStore = error;


  // Mutations
  @Mutation
  public setSites(sites: IAssignmentTreeNodeViewModel[]) {
    this.sites = sites;
  }
  @Mutation
  public setAssets(assets: IAssignmentTreeNodeViewModel[]) {
    this.assets = assets;
  }
  @Mutation
  public setSite(site: IAssignmentTreeNodeViewModel) {
    this.currentSite = site;
  }
  @Mutation
  public setAsset(asset: IAssignmentTreeNodeViewModel) {
    this.currentAsset = asset;
  }
  @Mutation
  public setIsCurrentlySaving(value: boolean) {
    this.isCurrentlySaving = value;
  }
  @Mutation
  public setOriginalFormulaJson(formula: string) {
    this.originalFormulaJson = formula;
  }
  @Mutation
  public setFormulaJson(formula: string) {
    this.formulaJson = formula;
  }
  @Mutation
  public setCurrentMode(value: string) {
    this.currentMode = value;
  }
  @Mutation
  public setLoadingSitesAndAssets(value: boolean) {
    this.isLoadingSitesAndAssets = value;
  }

  // Actions
  @Action({ rawError: true })
  public async prepareSitesAndAssets(): Promise<void> {
    this.setLoadingSitesAndAssets(true);
    this.setSite(null);
    this.setAsset(null);
    const inputs: IAssignmentTreeNodeViewModel[] = await AssignmentTreeService.createDefault().getAssignmentTreeNodes();
    const sites: IAssignmentTreeNodeViewModel[] = [];
    const assets: IAssignmentTreeNodeViewModel[] = [];
    for (const level of inputs) {
      switch (level.type) {
        case EntityType.CustomerSite: sites.push(level); break;
        case EntityType.Heater: assets.push(level); break;
      }
    }
    this.setSites(sites);
    this.setAssets(assets);
    this.setLoadingSitesAndAssets(false);
  }
  @Action({ rawError: true })
  public async saveEdits(payload: IUpdateVariableViewModel): Promise<IAssetReportVariableViewModel> {
    this.setIsCurrentlySaving(true);
    const apiVersion =  ApiVersion.Two;
    try {
      const response = await VariableEditorService.createDefault().saveVariable(payload, apiVersion);
      return response;
    } finally {
      this.setIsCurrentlySaving(false);
    }
  }
  @Action({ rawError: true })
  public async deleteVariable(payload: IDeleteVariableViewModel): Promise<any> {
      const response = await VariableEditorService.createDefault().deleteVariable(payload);
      return response;

  }
  @Action({ rawError: true })
  public async deleteVariableValidation(payload: IDeleteVariableViewModel): Promise<any> {
      const response = await VariableEditorService.createDefault().deleteVariableValidation(payload);
      return response;

  }
  @Action({ rawError: true })
  public async copyVariables(payload: ICopyVariableRequest): Promise<any> {
     try {
      const response = await VariableEditorService.createDefault().copyVariables(payload);
      return response;
     } catch ( error ) {
      const errorString = `From copy variables: ${(error as Error)?.message}\n`;
      this.errorStore.setError({
        error: error,
        errorString,
        handleError: true,
        routeHomeAfterError: false
      });
     }
  }

  @Action({ rawError: true })
  public async copyCustomVariables(payload: ICopyVariableRequest): Promise<any> {
    try {
      const response = await VariableEditorService.createDefault().copyCustomVariables(payload);
      return response;
    } catch ( error ) {
      const errorString = `From copy custom variables: ${(error as Error)?.message}\n`;
      this.errorStore.setError({
        error: error,
        errorString,
        handleError: true,
        routeHomeAfterError: false
      });
    }
  }

  @Action({ rawError: true })
  public async evaluateFormula(payload: any): Promise<any> {
    try {
      const response = await VariableEditorService.createDefault().evaluateFormula(payload);
      return response;
    }
    finally {
      //this.setIsCurrentlySaving(false);
    }
  }
  @Action({ rawError: true })
  public async GetLatestValue(payload: IVariablesLatestDataRequestViewModel): Promise<IVariableDataResponseViewModel[]> {
    try {
      const response = await VariableEditorService.createDefault().GetLatestValue(payload);
      return response;
    }
    finally {
      //this.setIsCurrentlySaving(false);
    }
  }
}

export default getModule(EditorModule, store);
