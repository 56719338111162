import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { IUpdateVariableViewModel, IAssetReportVariableViewModel, CalculatorFormula, IDeleteVariableViewModel,
  IVariablesLatestDataRequestViewModel, IVariableDataResponseViewModel, ICopyVariableRequest, ICopyVariableResponse } from '@/view-models/variables';
import { http } from './common/http';
import { ApiVersion } from '@/enums/api-version';
import store from '@/store';

export class EvaluateFormulaRequest {
  public assetKey: string;
  public formulaJson: string;
}
// Uses base http instance
export default class VariableEditorService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('variable-editor', axios);
    }

    public static createDefault(): VariableEditorService {
      return new VariableEditorService(http.instance);
    }

    public async saveVariable(payload: IUpdateVariableViewModel, apiVersion?: string): Promise<IAssetReportVariableViewModel> {
        return (await this.http.post<IAssetReportVariableViewModel>('variable-editor', payload, { headers: { 'api-version': apiVersion }})).data;
    }

    public async patchSaveVariable<T>(query?: Record<string, any>, payload?: any): Promise<T> {
      const config: AxiosRequestConfig = {
        params: query || undefined,
        headers: { 'api-version': ApiVersion.Two }
      };
      return this.http.patch<T>('variable-editor', payload, config).then((result) => result.data);
    }

   /**
   * Evaluate whether the formula expression is valid or not
   * @param calculatorRequest : CalculatorFormula
   * @returns {Promise<string>}
   */
  public evaluateFormula(calculatorRequest: CalculatorFormula): Promise<string> {
    let config: AxiosRequestConfig = {};
    
    const apiVersion =  ApiVersion.Two;
    const headers = { "api-version": apiVersion };
    if (headers != null) {
      config.headers = headers;
    }

    return this.http.post(`variables/evaluateformula`, calculatorRequest, config);
  }

  public GetLatestValue(variableDataRequest: IVariablesLatestDataRequestViewModel):Promise<IVariableDataResponseViewModel[]>{
    return this.http.post(`variables/data/latest`, variableDataRequest).then((response: AxiosResponse<IVariableDataResponseViewModel[]>) => {
      return response.data;
    });
  }

  public deleteVariable(deletePayload: IDeleteVariableViewModel){
    return this.http.post(`variables/deletevariable`, deletePayload);
  }

  public deleteVariableValidation(deletePayload: IDeleteVariableViewModel){
    return this.http.post(`variables/ReferencedVariables`, deletePayload);
  }

  public copyVariables(copyRequest: ICopyVariableRequest) {
    return this.http.post<ICopyVariableResponse>(`variables/copyVariables`, copyRequest);
  }

  public copyCustomVariables(copyRequest: ICopyVariableRequest) {
    return this.http.post(`variables/copyCustomVariables`, copyRequest);
  }
}
