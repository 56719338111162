
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '.';
import { UserService } from '@/services/user-service';

export interface IAppModule {
  // State
  isLoading: boolean;
  isAppInitialized: boolean;
  activeCustomerKey: string;
  userPermissions: string[];
  // Getters
  // Mutations
  setIsLoading(value: boolean): void;
  setIsAppInitialized(value: boolean): void;
  setActiveCustomerKey(key: string): void;
  setUserPermissions(values: string[]): void;
  // Actions
  getUserPermissions(): void;
  getCurrentUser():void;
}

@Module({ dynamic: true, store, name: 'app'})
export class AppModule extends VuexModule implements IAppModule {
  // State
  public isLoading: boolean = true;
  public isAppInitialized: boolean = false;
  public activeCustomerKey: string = '';
  public userPermissions: string[] = [];
  public currentUser: any = {};
  // Getters
  // Mutations
  @Mutation
  public setIsLoading(value: boolean): void {
    this.isLoading = value;
  }
  @Mutation
  public setIsAppInitialized(value: boolean): void {
    this.isAppInitialized = this.isAppInitialized || value;
  }
  @Mutation
  public setActiveCustomerKey(key: string): void {
    this.activeCustomerKey = key;
  }
  @Mutation
  public setUserPermissions(values: string[]): void {
    this.userPermissions = values;
  }
  @Mutation
  public setCurrentUser(user: any): void {
    this.currentUser = user;
  }

  // Actions
  @Action({ rawError: true })
  public async getUserPermissions() {
    const userService = UserService.factory();
    const permissions = await userService.getUserPermissions();
    this.context.commit('setUserPermissions', permissions);
  }
   @Action({ rawError: true })
  public async getCurrentUser() {
    const userService = UserService.factory();
    const currentUser = await userService.getCurrentUser();
    this.context.commit('setCurrentUser', currentUser);
  }
}

export default getModule(AppModule, store);
