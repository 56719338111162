import { MeasurementTypesEnum, UnitOfMeasurementEnum } from '@/enums/variables';

export function getDefaultUoM(measurementType: MeasurementTypesEnum): UnitOfMeasurementEnum {
  // This "default" uom current represents what units we expect for the stored data for a given measurement type.
  // We'll likely want to reevaluate this at some point after the Ember data contract has been fully established.
  switch (measurementType) {
    case MeasurementTypesEnum.Pressure:
    case MeasurementTypesEnum.FuelPressure:
      return UnitOfMeasurementEnum.Pa;
    case MeasurementTypesEnum.Temperature:
      return UnitOfMeasurementEnum.degK;
    case MeasurementTypesEnum.Distance:
      return UnitOfMeasurementEnum.m;
    case MeasurementTypesEnum.FlowRate:
      return UnitOfMeasurementEnum.kgs;
    case MeasurementTypesEnum.Concentration:
      return UnitOfMeasurementEnum.ppm;
    case MeasurementTypesEnum.Energy:
      return UnitOfMeasurementEnum.W;
    case MeasurementTypesEnum.O2MoleFraction:
      return UnitOfMeasurementEnum.WET;
    case MeasurementTypesEnum.FuelPropertyEnergy:
      return UnitOfMeasurementEnum.BTUscf;
    case MeasurementTypesEnum.Numeric:
      return UnitOfMeasurementEnum.Fraction;
    case MeasurementTypesEnum.Boolean:
      return UnitOfMeasurementEnum.Bool;
    case MeasurementTypesEnum.Area:
      return UnitOfMeasurementEnum.sqft;
    case MeasurementTypesEnum.Text:
      return UnitOfMeasurementEnum.String;
    case MeasurementTypesEnum.Unknown:
    default:
      return UnitOfMeasurementEnum.Default;
  }
}
