import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { IAssetHierarchyNodeViewModel } from '@/view-models/variables';
import { http } from './common/http';


export default class ReportAssetHierarchyService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('report-asset-hierarchy', axios); 
    }

    public static createDefault(): ReportAssetHierarchyService {
      return new ReportAssetHierarchyService(http.instance);
    }

    public async getLevelsUnderAsset(assetKey: string, apiVersion?: string): Promise<IAssetHierarchyNodeViewModel[]> {
    const headers = { "api-version": apiVersion };
      const response = await this.httpGet<IAssetHierarchyNodeViewModel[]>(assetKey, { includeAsset: true }, headers);
      return response ?? [];
    }
}
