import LoggerService from '@/services/logger-service';
import { eventBus } from './event-bus';
import { ErrorLogViewModel, IErrorViewModel } from '@/view-models/logger';
import { hashString } from './string';

class Logger {
  public error(error: any) {
    // eslint-disable-next-line no-console
    // console.error(error);
    const entry: ErrorLogViewModel = errorToErrorLogViewModel(error);
    this.sendLogError(entry);
  }

  private async sendLogError(logEntry: ErrorLogViewModel) {
    const logger: LoggerService = LoggerService.createDefault();
    try {
      return await logger.postError(logEntry);
    } catch (e) {
      const message = 'The platform is temporarily unavailable';
      const hash = hashString(message, true);
      const errorViewModel: IErrorViewModel = {
        message,
        hash,
        id: hash,
        redirect: null
      };
      eventBus.$emit('error-modal-event', errorViewModel);
      throw e;
    }
  }
}

export default new Logger();

export function errorToErrorLogViewModel(error: any): ErrorLogViewModel {
  const logEntry: ErrorLogViewModel = new ErrorLogViewModel();

  if (error.ExceptionMessage != null) {
    logEntry.Message = error.ExceptionMessage;
    logEntry.ErrorCode = hashString(
      `${error.ExceptionMessage}${logEntry.BrowserName}${logEntry.Url}`,
      true
    );
  } else if (error.Message != null) {
    logEntry.Message = error.Message;
    if (error.Data != null) {
      const data = error.Data;
      if (data.ErrorCode != null) {
        logEntry.ErrorCode = data.ErrorCode;
      } else {
        logEntry.ErrorCode = hashString(logEntry.Message, true);
      }
      if (data.ClientErrorId != null) {
        logEntry.ClientErrorId = data.ClientErrorId;
      }
    }
  } else if (error.message != null) {
    logEntry.Message = error.message;
    logEntry.ErrorCode = hashString(error.toString(), true);
  } else {
    logEntry.Message = error;
    logEntry.ErrorCode = hashString(`${error}${logEntry.BrowserName}${logEntry.Url}`, true);
  }

  if (error.StackTrace != null) {
    logEntry.StackTrace = JSON.stringify(error.StackTrace);
  } else if (error.StackTraceString != null) {
    logEntry.StackTrace = error.StackTraceString;
  } else if (error.stack) {
    logEntry.StackTrace = JSON.stringify(error.stack);
  } else {
    logEntry.StackTrace = JSON.stringify(error);
  }

  return logEntry;
}
