



































// Libraries
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ToastStatus } from '@/enums/toast';
// View Models
// Components
// Services
// Store

@Component({
  name: 'Toast'
})
export default class extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public id: string;
  @Prop({ default: '' })
  public headerText: string;
  @Prop({ default: '' })
  public message: string;
  @Prop({ default: ToastStatus.Information })
  public status: ToastStatus;
  @Prop({ default: false })
  public shown: boolean;
  @Prop({ default: null })
  public duration: number;
  @Prop({ default: null })
  public icon: string;
  @Prop({ default: false })
  public append: boolean;
  @Prop({ default: 'b-toaster-top-right' })
  public toaster: string;
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('hidden')
  public hidden(unused: string): void {}
}
