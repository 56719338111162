











// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataPartEnum } from '@/enums/calculator-enums';
// View Models
import CalcDataPart from '@/components/calculator/sub-components/DataPart.vue';
import { DataPart, Formula, LogicStatement } from '@/components/calculator/classes';
// Components
// Services
@Component({
  name: 'calculator-formula',
  components: {
    CalcDataPart
  },
  model: {},
})
export default class CalculatorFormula extends Vue {
  // VUE.JS Props
  @Prop()
  public formula: Formula;
  @Prop()
  public current: number;
  @Prop()
  public length: number;
  // VUEX
  // Properties
  // Fields

  // Getters
  get hasMultipleAnswers(): boolean {
    let variableFound: boolean = false;
    this.formula.data.forEach(i => findFlags(i));

    return variableFound;

    function findFlags(obj: DataPart) {
      if (obj.type === DataPartEnum.Variable || obj.type === DataPartEnum.Function) {
        variableFound = true;
      } else if (obj.type === DataPartEnum.LogicStatement) {
        (obj as LogicStatement).if.forEach(i => findFlags(i));
        (obj as LogicStatement).then.forEach(i => findFlags(i));
        (obj as LogicStatement).else.forEach(i => findFlags(i));
      }
    }
  }
  // Lifecycle Handlers

  // Helper Methods

  // Event Methods

  // Watchers

  // Emitters
}
