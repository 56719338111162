import Vue from 'vue';
import Vuex from 'vuex';
import { IErrorModule } from './error';
import { IAppModule } from './app';

Vue.use(Vuex);

interface IState {
  app: IAppModule;
  error: IErrorModule;
}

export default new Vuex.Store<IState>({});
