










































// Libraries
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { elementId } from '@/utils/component-utils';
// View Models
// Components
// Stores

@Component({
  name: 'prompt-modal'
})
export default class PromptModal extends Vue {
  // VUE.JS Props
  @Prop({ default: false })
  public isSaving: boolean;
  // VUEX
  // Properties
  public showModal: boolean = false;
  public id: string = 'prompt-modal';
  // Fields
  // Getters

  public elementId: (
    entityType: string,
    propertyOrActionOrInputName: string,
    entityKey?: string
  ) => string = elementId;
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // private mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  public showPromptModal(): void {
    this.showModal = true;
  }
  public discardChanges(): void {
    this.discard();
  }
  public hideModal() {
    this.showModal = false;
  }
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('save')
  public save() {
    this.showModal = false;
  }
  @Emit('discard')
  public discard() {}
  @Emit('cancel')
  public cancel() {}
  @Emit('hidden')
  public modalClosed() {}
}
