






























// Libraries
import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import type { BDropdown } from 'bootstrap-vue';
import { parentEventBus } from '@/utils/from-parent';
import { ParentEvents } from '@/utils/event-bus';
import { alphabeticSorter } from '@/utils/array-utils';
// View Models
import type { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
// Components
// Stores
import editor from '@/store/editor';
import inputsTree from '@/store/inputs-tree';
import { TreeFilterEnum } from '@/enums/variables';

@Component({
  name: 'home-page-header',
})
export default class HomePageHeader extends Vue {
  // VUEX
  public get sites(): IAssignmentTreeNodeViewModel[] {
    const sites = editor.sites ?? [];

    sites.sort(alphabeticSorter<IAssignmentTreeNodeViewModel>((node) => node.name));

    return sites;
  }
  public get assets(): IAssignmentTreeNodeViewModel[] {
    const assets = this.selectedSite != null
      ? editor.assets.filter((asset) => asset.customerSiteKey === this.selectedSite.key)
      : [];

    assets.sort(alphabeticSorter<IAssignmentTreeNodeViewModel>((node) => node.name));

    return assets;
  }

  // Properties
  public siteDropdownHelper: boolean = false;
  public assetDropdownHelper: boolean = false;

  // Fields
  // Getters
  public get hasSites(): boolean {
    return this.sites.length > 0;
  }
  public get selectedAsset(): IAssignmentTreeNodeViewModel {
    return editor.currentAsset;
  }
  public get selectedSite(): IAssignmentTreeNodeViewModel {
    return editor.currentSite;
  }
  public get selectedNodeIsClean(): boolean {
    return inputsTree.selectedNodeIsClean;
  }

  // Refs
  @Ref('siteSelectorDropdown')
  private siteDropdownRef!: BDropdown;
  @Ref('assetSelectorDropdown')
  private assetDropdownRef!: BDropdown;

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    parentEventBus()?.$on(ParentEvents.ActiveCustomerChanged, this.resetSiteAssetSelection);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  public beforeDestroy(): void {
    parentEventBus()?.$off(ParentEvents.ActiveCustomerChanged, this.resetSiteAssetSelection);
  }
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private resetSiteAssetSelection() {
    editor.setSite(null);
    editor.setAsset(null);
    inputsTree.clearNode();
  }

  // Event Methods
  public onSiteDropdownShown(unused: any) {
    this.siteDropdownRef.show();
    this.assetDropdownRef.hide();
  }
  public onAssetDropdownShown(unused: any) {
    this.assetDropdownRef.show();
    this.siteDropdownRef.hide();
  }
  public selectModelSite(val: IAssignmentTreeNodeViewModel) {
    if (this.selectedNodeIsClean) {
      this.changeSite(val);
    } else {
      this.showUnsavedModal(() => this.changeSite(val));
    }
  }
  private changeSite(val: IAssignmentTreeNodeViewModel) {
    editor.setSite(val);
    editor.setAsset(null);
    inputsTree.clearNode();
  }
  public selectModelAsset(val: IAssignmentTreeNodeViewModel) {
    inputsTree.setSelectedTreeFilter(TreeFilterEnum.All);
    if (this.selectedNodeIsClean) {
      editor.setAsset(val);
       inputsTree.clearNode();
    } else {
      this.showUnsavedModal(() => {
        editor.setAsset(val);
         inputsTree.clearNode();
        });
    }
  }

  @Emit('show-unsaved-modal')
  public showUnsavedModal(returnFunction: any) {}
}
