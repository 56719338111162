import {
  IDataPart,
  IFormula,
  ILogicStatement,
  IFunction,
  IEmpty,
  IVariable,
  IConstant,
  IOperator,
  ILeftParenthesis,
  ITime,
  IRightParenthesis,
  IKeyPair
} from '@/view-models/calculator-view-models';
import {
  DataPartEnum,
  FunctionEnum,
  OperatorEnum,
  LogicalOperatorEnum,
  TimeEnum,
} from '@/enums/calculator-enums';
import HelperMethods from '@/utils/helper-methods';

export class DataPart implements IDataPart {
  public key: string = HelperMethods.newGuid();
  public type: DataPartEnum;
  public parent: IFormula | ILogicStatement | IFunction = null;
  public parentArray: Array<DataPart>;

  public get isValid(): boolean {
    return true;
  }

  public delete(): boolean {
    return true;
  }
}

export class Empty extends DataPart implements IEmpty {
  public key: string = HelperMethods.newGuid();
  public type: DataPartEnum = DataPartEnum.Empty;
  public placeholder: string = '';

  constructor(placeholder: string = '') {
    super();
    this.placeholder = placeholder;
  }

  public delete(): boolean {
    return false;
  }
}

export class Constant extends DataPart implements IConstant {
  public type: DataPartEnum = DataPartEnum.Constant;
  public number: string;

  constructor(number: string) {
    super();
    this.number = number === '.' ? '0.' : number;
    if (this.number === null) {
      throw new Error('Number must be defined.');
    }
  }

  public get isValid(): boolean {
    //check if this is necessary
    if (this.number) {
    const thisNum = this.number.toString();
    if (thisNum.substr(thisNum.length - 1, thisNum.length) === '.') {
      return false;
    }
    if (thisNum.split('').filter(n => n === '.').length > 1) {
      return false;
    }
  }

    return true;
  }

  public delete(): boolean {
    if (this.number.toString().length > 1) {
      return false;
    }
    return true;
  }

  public update(newValue: string = null): boolean {
    const thisNum = this.number.toString();
    if (!newValue) {
      this.number = thisNum.substr(0, thisNum.length - 1);
      return true;
    }

    switch (newValue) {
      case '-':
        this.number = thisNum.substr(0, 1) === '-' ? thisNum.substr(1, this.number.length) : '-' + this.number;
        return true;
      case '.':
        if (thisNum.includes(newValue)) {
          return false;
        }
        break;
      case '%':
        const float = Number.parseFloat(this.number);
        if (!isNaN(float)) {
          this.number = (float / 100).toString();
          return true;
        }
        return false;
      default:
        break;
    }

    this.number += newValue;
    return true;
  }
}

export class Formula implements IFormula {
  public key: string = HelperMethods.newGuid();
  public type: DataPartEnum | any = DataPartEnum.Formula;
  public data: Array<DataPart> = [];
  public templateName: string = null;
  public version: number = 0;

  // client only
  public answer: string = null;
  public variableKeyDisplayNamePair: Array<IKeyPair<string, string>>;

  constructor() {
    this.createPart(new Empty(), null);
  }

  public referencedVariableKeys(): Array<string> {
    return this.data.filter(part => part.type === DataPartEnum.Variable).map(p => (p as Variable).variableKey);
  }

  private buildRecursively(
    genericObj: DataPart | any,
    parentDataPart: Formula | LogicStatement | Function,
    array: Array<DataPart>,
    isCopy: boolean
  ): DataPart {
    let newDataPart: any = null;

    switch (genericObj.type) {
      case DataPartEnum.Constant:
        newDataPart = new Constant((genericObj as Constant).number);
        break;
      case DataPartEnum.Function:
        newDataPart = new Function((genericObj as Function).function);
        (genericObj as Function).variables.forEach(i => this.buildRecursively(i, newDataPart, newDataPart.variables, isCopy));
        break;
      case DataPartEnum.LogicStatement:
        newDataPart = new LogicStatement();
        (genericObj as LogicStatement).if.forEach(i => this.buildRecursively(i, newDataPart, newDataPart.if, isCopy));
        (genericObj as LogicStatement).then.forEach(i =>
          this.buildRecursively(i, newDataPart, newDataPart.then, isCopy)
        );
        (genericObj as LogicStatement).else.forEach(i =>
          this.buildRecursively(i, newDataPart, newDataPart.else, isCopy)
        );
        break;
      case DataPartEnum.LogicalOperator:
        newDataPart = new LogicalOperator((genericObj as LogicalOperator).operator);
        break;
      case DataPartEnum.LeftParenthesis:
        newDataPart = new LeftParenthesis();
        break;
      case DataPartEnum.Operator:
        newDataPart = new Operator((genericObj as Operator).operator);
        break;
      case DataPartEnum.RightParenthesis:
        newDataPart = new RightParenthesis();
        break;
      case DataPartEnum.Variable:
        const obj = genericObj as Variable;
        obj.variableName = obj.variableName  ? obj.variableName : obj.variableKey;
        newDataPart = new Variable((genericObj as Variable).variableKey, (genericObj as Variable).variableName);
        break;
      default:
        return;
    }

    if (!isCopy) {
      newDataPart.key = genericObj.key;
    }
    parentDataPart.createPart(newDataPart, null, array);
  }

  /*
  * Filter out any empty objects/placeholders and references to recursive objects (parent, parentArray)
  */
  private cleanRecursively(obj: DataPart | any): void {
    if (!obj) {
      return;
    }

    if (obj.type === DataPartEnum.Empty) {
      const pArray = obj.parentArray;
      pArray.splice(obj.parentArray.indexOf(obj), 1);
      pArray.forEach((i: any) => this.cleanRecursively(i));
    } else {
      Object.keys(obj).forEach((i) => {
        if (['parent', 'parentArray', 'placeholder'].includes(i)) {
          delete obj[i];
        } else if (Array.isArray(obj[i])) {
          obj[i].forEach((j: any) => this.cleanRecursively(j));
        }
      });
    }
  }

  public get isValid(): boolean {
    if (typeof this.data === 'undefined' || this.data.length <= 0) {
      return false;
    }
    if (this.data.length === 1 && this.data[0].type === DataPartEnum.Empty) {
      return false;
    }

    return this.data.every((part: IDataPart) => part.isValid);
  }

  public build(formula: IFormula, isCopy: boolean = false): void {
    if (!isCopy) {
      this.key = formula.key;
      this.version = formula.version;
      this.answer = formula.answer;
    }
    formula.data.forEach(i => this.buildRecursively(i, this, this.data, isCopy));
  }

  public createPart(part: DataPart, focus: DataPart, array: Array<DataPart> = this.data): boolean {
    let index = array.indexOf(focus);
    part.parent = this;
    part.parentArray = array;

    if (index < 0) {
      index = array.length - 1;
    }

    array.splice(index + 1, 0, part);

    return true;
  }

  public delete(): boolean {
    return this.data.length < 1;
  }

  public deleteAll(): boolean {
    this.data = [];
    this.createPart(new Empty(), null);
    return true;
  }

  public deletePart(part: DataPart): boolean {
    const index = this.data.indexOf(part);
    if (index < 0) {
      return false;
    }

    if (part.delete()) {
      this.data.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }

  public prep(): IFormula {
    this.data.forEach(i => this.cleanRecursively(i));

    return this;
  }
}

export class Function extends DataPart implements IFunction {
  public type: DataPartEnum = DataPartEnum.Function;
  public function: FunctionEnum;
  public variables: Array<DataPart> = [];
  private placeholder: string = 'calculator.emptyVariables';

  constructor(func: FunctionEnum) {
    super();
    this.function = func;
    this.createPart(new Empty(this.placeholder), null, this.variables);
  }

  public get isValid(): boolean {
    if (typeof this.variables === 'undefined' || this.variables.length <= 0) {
      return false;
    }
    if (this.variables.length === 1 && this.variables[0].type === DataPartEnum.Empty) {
      return false;
    }
    return this.variables.every((part: IDataPart) => (part.type === DataPartEnum.Empty || part.type === DataPartEnum.Variable) && part.isValid);
  }

  public createPart(part: DataPart, focus: DataPart, array: Array<DataPart> = null): boolean {
    if (part.type !== DataPartEnum.Empty && part.type !== DataPartEnum.Variable) {
      return false;
    }

    let index = focus && focus.parentArray !== undefined && focus.parentArray ? focus.parentArray.indexOf(focus) : -1;
    part.parent = this;
    part.parentArray = focus && focus.parentArray !== undefined && focus.parentArray ? focus.parentArray : array;
    const empty = part.parentArray.find(p => p.type === DataPartEnum.Empty) as Empty;

    if (index < 0) {
      index = part.parentArray.length - 1;
    }

    part.parentArray.splice(index + 1, 0, part);

    this.changeEmptyValue(part, empty);

    return true;
  }

  public deletePart(part: DataPart): boolean {
    const index = part && part.parentArray !== undefined && part.parentArray ? part.parentArray.indexOf(part) : -1;
    const empty = part.parentArray.find(p => p.type === DataPartEnum.Empty) as Empty;

    if (index < 0) {
      return false;
    }

    if (part.delete()) {
      part.parentArray.splice(index, 1);

      this.changeEmptyValue(part, empty);

      return true;
    } else {
      return false;
    }
  }

  private changeEmptyValue(part: DataPart, empty: Empty) {
    if (part.parentArray.length > 1) {
      if (empty && empty.placeholder) {
        empty.placeholder = '';
      }
    } else if (part.parentArray.length > 0) {
      if (empty && !empty.placeholder) {
        empty.placeholder = this.placeholder;
      }
    }
  }
}

export class LogicStatement extends DataPart implements ILogicStatement {
  public type: DataPartEnum = DataPartEnum.LogicStatement;
  public if: Array<DataPart> = [];
  public then: Array<DataPart> = [];
  public else: Array<DataPart> = [];
  private placeholder: string = 'calculator.emptyValue';

  constructor() {
    super();
    this.createPart(new Empty(this.placeholder), null, this.if);
    this.createPart(new Empty(this.placeholder), null, this.then);
    this.createPart(new Empty(this.placeholder), null, this.else);
  }

  public get isValid(): boolean {
    const props = [this.if, this.then, this.else];

    return props.every((p: Array<IDataPart>) => {
      if (typeof p === 'undefined' || p.length <= 0) {
        return false;
      }
      if (p.length === 1 && p[0].type === DataPartEnum.Empty) {
        return false;
      }

      return p.every((part: IDataPart) => part.isValid);
    });
  }

  public createPart(part: DataPart, focus: DataPart, array: Array<DataPart> = null): boolean {
    let index = focus && focus.parentArray !== undefined && focus.parentArray ? focus.parentArray.indexOf(focus) : -1;
    part.parent = this;
    part.parentArray = focus && focus.parentArray !== undefined && focus.parentArray ? focus.parentArray : array;
    const empty = part.parentArray.find(p => p.type === DataPartEnum.Empty) as Empty;

    if (index < 0) {
      index = part.parentArray.length - 1;
    }

    part.parentArray.splice(index + 1, 0, part);

    this.changeEmptyValue(part, empty);

    return true;
  }

  public deletePart(part: DataPart): boolean {
    const index = part && part.parentArray !== undefined && part.parentArray ? part.parentArray.indexOf(part) : -1;
    const empty = part.parentArray.find(p => p.type === DataPartEnum.Empty) as Empty;

    if (index < 0) {
      return false;
    }

    if (part.delete()) {
      part.parentArray.splice(index, 1);

      this.changeEmptyValue(part, empty);

      return true;
    } else {
      return false;
    }
  }

  private changeEmptyValue(part: DataPart, empty: Empty) {
    if (part.parentArray.length > 1) {
      if (empty && empty.placeholder) {
        empty.placeholder = '';
      }
    } else if (part.parentArray.length > 0) {
      if (empty && !empty.placeholder) {
        empty.placeholder = this.placeholder;
      }
    }
  }
}

export class Operator extends DataPart implements IOperator {
  public type: DataPartEnum = DataPartEnum.Operator;
  public operator: OperatorEnum;

  constructor(operator: OperatorEnum) {
    super();
    this.operator = operator;
    if (this.operator === undefined || !this.operator) {
      throw new Error('Operator type must be defined.');
    }
  }
}

export class LogicalOperator extends DataPart implements IOperator {
  public type: DataPartEnum = DataPartEnum.LogicalOperator;
  public operator: LogicalOperatorEnum;

  constructor(operator: LogicalOperatorEnum) {
    super();
    this.operator = operator;
    if (this.operator === undefined || !this.operator) {
      throw new Error('Operator type must be defined.');
    }
  }
}

export class LeftParenthesis extends DataPart implements ILeftParenthesis {
  public type: DataPartEnum = DataPartEnum.LeftParenthesis;
}

export class RightParenthesis extends DataPart implements IRightParenthesis {
  public type: DataPartEnum = DataPartEnum.RightParenthesis;
}

export class Time extends DataPart implements ITime {
  public type: DataPartEnum = DataPartEnum.Time;
  public arg: DataPart;
  public number: string;
  public unit: TimeEnum;
}

export class Variable extends DataPart implements IVariable {
  public type: DataPartEnum = DataPartEnum.Variable;
  public variableKey: string;
  public variableName: string;

  constructor(varKey: string, varName: string) {
    super();
    this.variableKey = varKey;
    this.variableName = varName;
    if (this.variableKey === undefined || !this.variableKey) {
      throw new Error('Variable key must be defined.');
    }
    if (this.variableName === undefined || !this.variableName) {
      throw new Error('Variable name must be defined.');
    }
  }
}