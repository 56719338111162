







































































































// Libraries
import { Component, Vue, Watch } from 'vue-property-decorator';
// View Models
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { Nil } from '@/utils/types';
import { IDeleteVariableViewModel, IUpdateVariableViewModel, VariableTreeNode } from '@/view-models/variables';
// Components
import HomePageHeader from '@/components/HomePageHeader.vue';
import VariableTree from '@/components/variable-tree/VariableTree.vue';
import VariableTreeOld from '@/components/variable-tree/VariableTreeOld.vue';
import SelectedVariablePane from '@/components/SelectedVariablePane.vue';
import MergeHistoriesPane from '@/components/MergeHistoriesPane.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import Loading from '@/components/common/Loading.vue';

// Store
import editor from '@/store/editor';
import inputsTree from '@/store/inputs-tree';
import app from '@/store/app';
import { parentEventBus } from '@/utils/from-parent';
import Toaster from '@/components/common/Toaster.vue';
import Toast from '@/components/common/Toast.vue';
import { AccessSettingEnum, MeasurementTypesEnum } from '@/enums/variables';
import error from '@/store/error';
// Enums
import { CurrentMode } from '@/enums/current-mode';

@Component({
  name: 'home-page',
  components: {
    HomePageHeader,
    Loading,
    VariableTree,
    VariableTreeOld,
    SelectedVariablePane,
    MergeHistoriesPane,
    PromptModal,
    Toast,
    Toaster,
  }
})
export default class Home extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX
  // Properties
  public parentOptions: any = {};
  public modalReturnFunction(): void {}
  public currentModeEnum = CurrentMode;
  public initValues: any = {};
  // Fields
  private isCurrentlySaving: boolean = false;
  // Getters
  public get sites(): IAssignmentTreeNodeViewModel[] {
    return editor.sites ?? [];
  }
  public get enrichCalculatorFlag(): boolean {
    return true;
  }
  public get customVariableValidation() : boolean {
    return this.enrichCalculatorFlag && !app.currentUser.isCustomerUser;
  }
  public get currentMode() {
    if (editor.currentMode === CurrentMode.Create){
    this.initVariableForm();
  }
    return editor.currentMode;
  }
  public get selectedVariable(): Nil<VariableTreeNode> {
    return inputsTree.selectedNode;
  }
  public get selectedNodeIsClean(): boolean {
    return inputsTree.selectedNodeIsClean;
  }
  public get currentAsset(): IAssignmentTreeNodeViewModel {
    return editor.currentAsset;
  }
  public get isInMergePhase() {
    return inputsTree.inMergePhase;
  }
  public get isSaving() {
    return editor.isCurrentlySaving || this.isCurrentlySaving;
  }
  public get variables(): VariableTreeNode[] {
    let variables: VariableTreeNode[] = [];
    inputsTree.selectedNodeKeysForMerge.forEach((key) => {
      variables.push(inputsTree.nodeByKey(key, inputsTree.treeHelper.allNodes));
    });
    variables = this.addHistoryMock(variables); // this is to be removed once history as been added to node objects

    return variables.length > 0 ? variables.filter((variable) => variable != null) : [];
  }
  public get errorMessage(): string {
    return error.error;
  }
  public get isLoadingSitesAndAssets(): boolean {
    return editor.isLoadingSitesAndAssets;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}

  public async mounted(): Promise<void> {
    await editor.prepareSitesAndAssets();
    parentEventBus()?.$on('show-variable-editor-prompt-modal', this.handleShowPromptModal);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  public beforeDestroy(): void {
    parentEventBus()?.$off('show-variable-editor-prompt-modal', this.handleShowPromptModal);
  }
  // destroyed(): void {}
  // Private Methods
  private handleShowPromptModal(options: any): void {
    (this.$refs.PromptModal as PromptModal).showPromptModal();
    this.parentOptions = options;
    this.modalReturnFunction = this.continueNavigation;
  }
  public showPromptModal(returnFunction: any): void {
    this.modalReturnFunction = returnFunction || (() => null);
    (this.$refs.PromptModal as PromptModal).showPromptModal();
  }

  private closePromptModal(): void {
    (this.$refs.PromptModal as PromptModal).hideModal();
    this.modalReturnFunction();
    this.modalReturnFunction = (() => null);
  }

  public saveVariable(): void {
    const pane = this.$refs.selectedVariablePane as SelectedVariablePane;
    if (editor.currentMode === CurrentMode.Create) {
      pane.showConfirmation();
    } else {
      pane.saveVariable();
    }
  }
  public cancelVariable() {
    editor.setCurrentMode(CurrentMode.Edit);
    inputsTree.clearNode();
  }
  public discardChanges(): void {
    inputsTree.clearNode();
    this.closePromptModal();
  }
  public cancelModal(): void {
    this.modalReturnFunction = (() => null);
    this.closePromptModal();
  }
  public modalClosed(): void {
    parentEventBus()?.$emit('variable-editor-confirm-modal-closed');
  }
  private continueNavigation() {
    if (typeof this.parentOptions === 'object'
    && this.parentOptions.hasOwnProperty('next')
    && this.parentOptions.hasOwnProperty('to')) {
      if (this.parentOptions.to) {
        parentEventBus()?.$emit('update-navigation', this.parentOptions.to);
        if (this.parentOptions.next != null) {
          this.parentOptions.next();
        }
      }
      this.parentOptions = {};
    }
  }

  public initVariableForm() {
     this.initValues = { data: {
      displayName: this.$t('home.variables.newVariable').toString(),
      measurementType: MeasurementTypesEnum.Numeric,
      accessSettings: [AccessSettingEnum.Dashboards, AccessSettingEnum.Reports]
    }};
  }
  // Helper Methods
  public addHistoryMock(variables: VariableTreeNode[]): VariableTreeNode[] {
    const updatedVariables = variables;
    const histories = [
      {
        title: 'Merge 2 inputs on 02/17/20 at 3:45pm',
        input1: 'CLTP',
        input2: 'Heat Release'
      },
      {
        title: 'Merge 2 inputs on 12/1/20 at 2:00pm',
        input1: 'RREK',
        input2: 'Air Release'
      },
      {
        title: 'Merge 2 inputs on 10/20/20 at 11:00am',
        input1: 'Heat Max',
        input2: 'Water Flow'
      }
    ];
    if (updatedVariables) {
      updatedVariables.forEach((variable) => {
        if (variable) {
          variable.history = histories[Math.floor((Math.random() * histories.length))];
        }
      });
    }
    return updatedVariables;
  }
  public async updateVariable(payload: IUpdateVariableViewModel) {
    if (editor.currentMode === this.currentModeEnum.Create) {
      this.cancelVariable();
      try {
        await editor.saveEdits(payload);
        this.showToast('custom-variable-creation');
        inputsTree.clearNode();
        editor.setFormulaJson(null);
        editor.setOriginalFormulaJson(null);
        await inputsTree.loadTree(inputsTree.rootAsset);
      } catch {
         this.showToast('custom-variable-creation-error');
      }
    } else {
      this.isCurrentlySaving = true;
      const variable = await editor.saveEdits(payload);
      inputsTree.updateVariableNode({variable});
      editor.setOriginalFormulaJson(editor.formulaJson);
      this.isCurrentlySaving = false;
      this.closePromptModal();
    }
  }
  public async deleteVariable(payload: IDeleteVariableViewModel) {
    this.cancelVariable();
    await editor.deleteVariable(payload).then(() => {
      this.showToast('custom-variable-deletion');
    }).catch(() => {
        this.showToast('custom-variable-deletion-error');
    });

    inputsTree.clearNode();
    editor.setFormulaJson(null);
    editor.setOriginalFormulaJson(null);
    await inputsTree.loadTree(inputsTree.rootAsset);
  }
  // Event Methods
  public showToast(id: string) {
    this.$bvToast.show(id);
  }
  public hideToast(id: string) {
    this.$bvToast.hide(id);
  }

  // Watchers
  @Watch('selectedNodeIsClean')
  public updateSelectedVariable(value: boolean) {
    parentEventBus()?.$emit('editor-is-dirty', !value);
  }

  // Emitters
}
