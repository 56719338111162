









































// Libraries
import { Vue, VModel, Component, Prop } from 'vue-property-decorator';
import { eventBus } from "@/utils/event-bus";
// View Models
// Components
import { Nil } from '@/utils/types';
import { IVariableDataRequestViewModel, IVariablesLatestDataRequestViewModel, VariableTreeNode } from '@/view-models/variables';
import { AccessSettingEnum, TreeFilterEnum } from '@/enums/variables';
import { CurrentMode } from '@/enums/current-mode';
import Tooltip from '@/components/Tooltip.vue';
// Store
import editor from "@/store/editor";
import inputsTree from "@/store/inputs-tree";
import app from "@/store/app";
import { numberValueOutput } from '@/utils/number-utils';

@Component({
  name: 'tree-node-options',
  components: {
    Tooltip
  }
})

export default class TreeNodeOptions extends Vue {
  // VUE.JS Props
  @VModel({ required: true })
  public node!: Nil<VariableTreeNode>;
  @Prop({ default: false, type: Boolean })
  public showActions: boolean;

  // VUEX
  // Properties
  public latestValue: string = '';
  public latest: boolean = true;
  public processing: boolean = false;
  public isNoData: boolean = false;
  // Fields
  // Getters
  public get isCustom(): boolean {
    return this.node.data?.type === TreeFilterEnum.Custom;
  }
  public get isEnabledForVisualizations(): boolean {
    const validSettings: Array<AccessSettingEnum> = [
      AccessSettingEnum.Dashboards,
      AccessSettingEnum.Reports,
      AccessSettingEnum.All
      ];

    return validSettings.some(s => this.node.data?.accessSettings.includes(s));
  }
  public get isCreatingVariable(): boolean {
    return editor.currentMode === CurrentMode.Create;
  }
  public get canCreateVariables(): boolean {
    return !app.currentUser.isCustomerUser;
  }
  public get selectedNodeHasFormula(): boolean {
    return this.isCreatingVariable || inputsTree.selectedNode?.data?.type == TreeFilterEnum.Custom;
  }
  public get isSelectedNode(): boolean {
    return this.node?.data?.variableKey == inputsTree.selectedNode?.data?.variableKey;
  }
  public get canAddToCalculator(): boolean {
    if (!this.selectedNodeHasFormula || this.isSelectedNode) {
      return false;
    }
    return this.node?.isLeaf && !this.isCustom;
  }
  // Event Methods
  public createVariable(node: VariableTreeNode): void {
    inputsTree.clearNode();
    editor.setCurrentMode(CurrentMode.Create);
    eventBus.$emit('calculator-clear-formula');
    inputsTree.setCustomParentNode(node);
  }
  public addToCalculator(node: VariableTreeNode): void {
    const variableKeyName = {
        key: node.data.variableKey,
        value: node.data.variablePath,
      };
    eventBus.$emit('calculator-insert-variable', variableKeyName);
  }
  public deleteVariable(node: VariableTreeNode):void{
   eventBus.$emit("delete-node", node);
  }
  public copyVariable(node: VariableTreeNode):void{
   eventBus.$emit("calculator-copy-formula", node);
  }
  public async getLatestValue(node: VariableTreeNode): Promise<void> {
    const startTime: number = Date.now();
    this.latest = false;
    this.processing = true;
    let variableRequestPayLoad: IVariableDataRequestViewModel;
    variableRequestPayLoad = {
      key: node.data.variableKey,
      unitOfMeasurement: ''
    };
    let payload: IVariablesLatestDataRequestViewModel;
    payload = {
      variables: [variableRequestPayLoad],
      measurementSystemKey: ''
    };
    try {
      const result = await editor.GetLatestValue(
        payload);
      if (result.length > 0 && result[0].data.length > 0) {
        const dataObject = result[0].data[0];
        if (dataObject.length > 1 && dataObject[1] !== '' && dataObject[1] != null) {
          this.latestValue = numberValueOutput(Number(dataObject[1]));
        }
      }
    }
    catch (err) {

    }
    if (this.latestValue === '') {
      this.latestValue = "No Data";
      this.isNoData = true;
    }

    const minimumTime: number = 800;
    const timeRemainder: number = Date.now() - startTime;

    setTimeout(() => {
      this.processing = false;
    }, timeRemainder < minimumTime ? minimumTime - timeRemainder : 0);

  }
  // Watchers
  // Emitters
}
