import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import {
  IAssetVariablesViewModel,
  IAssetReportVariableViewModel,
} from '@/view-models/variables';
import { http } from './common/http';
import { AccessSettingEnum } from '@/enums/variables';

// Use base http instance
export default class AssetsService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('assets', axios);
  }

  public static createDefault(): AssetsService {
    return new AssetsService(http.instance);
  }

  public async getAssetReportVariables(assetKey: string, apiVersion: string): Promise<IAssetReportVariableViewModel[]> {
    const response = await this.http.get<IAssetVariablesViewModel>(
     this.url(`${assetKey}/variables?accessSetting=${AccessSettingEnum.All}`),{ headers: { 'api-version': apiVersion } });
    return response.data.variables ?? [];
  }
}
