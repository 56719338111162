













// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import eventBus from '@/utils/event-bus';
import { DataPartEnum } from '@/enums/calculator-enums';
// View Models
import { IDataPart } from '@/view-models/calculator-view-models';
// Components
import CalcDataPartConstant from '@/components/calculator/sub-components/Constant.vue';
import CalcDataPartEmpty from '@/components/calculator/sub-components/Empty.vue';
import CalcDataPartOperator from '@/components/calculator/sub-components/Operator.vue';
import CalcDataPartLogicalOperator from '@/components/calculator/sub-components/LogicalOperator.vue';
import CalcDataPartLeftParenthesis from '@/components/calculator/sub-components/LeftParenthesis.vue';
import CalcDataPartRightParenthesis from '@/components/calculator/sub-components/RightParenthesis.vue';
import CalcDataPartVariable from '@/components/calculator/sub-components/Variable.vue';
import CalcDataPartFunction from '@/components/calculator/sub-components/Function.vue';
import editor from "@/store/editor";
import { CurrentMode } from '@/enums/current-mode';
// Services
@Component({
  name: 'calc-data-part',
  components: {
    CalcDataPartConstant,
    CalcDataPartEmpty,
    CalcDataPartLogicStatement: () => import('@/components/calculator/sub-components/LogicStatement.vue'), // hard import on runtime to avoid circular ref
    CalcDataPartOperator,
    CalcDataPartLogicalOperator,
    CalcDataPartLeftParenthesis,
    CalcDataPartRightParenthesis,
    CalcDataPartVariable,
    CalcDataPartFunction
  },
  model: {},
})
export default class CalcDataPart extends Vue {
  // VUE.JS Props
  @Prop()
  public part: IDataPart;
  @Prop()
  public index: number;
  // VUEX

  // Properties
  public DataPartEnum = DataPartEnum;
  public isFocused = false;

  // Fields

  // Getters

  // Lifecycle Handlers
  private mounted(): void {
    eventBus.$on('calculator-focused-element', this.focusEvent);
    eventBus.$emit('calculator-notify-focus', this.part);
    eventBus.$emit('calculator-clear-error');
  }
  private beforeDestroy(): void {
    const previousDataPart = this.part.parentArray ? this.part.parentArray[this.index - 1] : null;
    if (previousDataPart) {
      eventBus.$emit('calculator-notify-focus', previousDataPart);
    }
    eventBus.$emit('calculator-clear-error');
  }
  // Helper Methods
  // Event Methods
  private focus() {
    eventBus.$emit('calculator-notify-focus', this.part);
  }

  private focusEvent(dataPart: any) {
    if (editor.currentMode !== CurrentMode.Edit) {
     this.isFocused = this.part === dataPart;
    }
  }

  // Watchers
  // Emitters
}
