import { Nil } from './types';

export type DateTimeInput = Date | string | number;

export function asDateTime(input: Nil<DateTimeInput>): Nil<Date> {
  if (input == null) {
    return null;
  }
  if (typeof input === 'string') {
    return new Date(Date.parse(input));
  }
  if (typeof input === 'number') {
    return new Date(input);
  }

  throw new Error(`ERROR: unknown date type: ${input}`);
}

export interface IDateTransformOptions {
  years?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

const transformMultiplierTable: Required<IDateTransformOptions> = {
  years: 52 * 7 * 24 * 60 * 60 * 1000,
  weeks: 7 * 24 * 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  minutes: 60 * 1000,
  seconds: 1000,
  milliseconds: 1
};

export function transformDate(input: DateTimeInput, options: IDateTransformOptions, action: 'add' | 'subtract'): Date {
  let milliseconds = asDateTime(input)?.valueOf() ?? 0;
  milliseconds = Object.entries(options).reduce((acc, entry) => {
    const [type, multiplier] = entry as [keyof IDateTransformOptions, number];
    const m = multiplier * transformMultiplierTable[type];
    return action === 'add' ? acc + m : acc - m;
  }, milliseconds);

  return asDateTime(milliseconds) as Date;
}

export function addDateTime(input: DateTimeInput, options: IDateTransformOptions) {
  return transformDate(input, options, 'add');
}

export function subtractDateTime(input: DateTimeInput, options: IDateTransformOptions) {
  return transformDate(input, options, 'subtract');
}

export function displayDate(input: DateTimeInput, locale?: string) {
  const date = asDateTime(input) as Date;
  const formatter = new Intl.DateTimeFormat(locale , {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
  return formatter.format(date);
}

export function displayDateTime(input: DateTimeInput, locale?: string) {
  const date = asDateTime(input) as Date;
  const formatter = new Intl.DateTimeFormat(locale , {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
  });
  return formatter.format(date);
}

export function startOfDay(input?: DateTimeInput): Date {
  const date = asDateTime(input ?? Date.now());
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    1, 0, 0, 0
  );
}

export function endOfDay(input?: DateTimeInput): Date {
  const date = asDateTime(input ?? Date.now());
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0, 0, 0, 0
  );
}

export function dateInBetween(start: DateTimeInput, value: DateTimeInput, end: DateTimeInput): boolean {
  const startDate = asDateTime(start);
  const valueDate = asDateTime(value);
  const endDate = asDateTime(end);

  return startDate > valueDate && valueDate < endDate;
}
