






































// Libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
// View Models
import { VariableTreeNode } from '@/view-models/variables';
// Components
import IconButton from '@/components/dsm/IconButton.vue';

@Component({
  name: 'merge-histories-pane',
  components: {
    IconButton
  }
})
export default class MergeHistoriesPane extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public variables!: VariableTreeNode[];
  @Prop({ default: false })
  public modalActive: boolean;
  public openHistory: string[] = [];
  // Refs
  // VUEX
  // Properties
  // Fields
  // Getters
  public getToggleIconClass(variable: string): string {
    return !this.openHistory.includes(variable) ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline';
  }
  public hideHistory(variable: string): boolean {
    return this.openHistory.includes(variable);
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public toggleHistory(variable: string) {
    if (!this.openHistory.includes(variable)) {
      this.openHistory.push(variable);
    } else {
      this.openHistory = this.openHistory.filter((item) => item !== variable);
    }
  }
  // Event Methods
  // Watchers
  // Emitters
}
