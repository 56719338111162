import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { umHttp } from './common/http';

export default class AssignmentTreeService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('users/current/assignmentTree', axios);
    }

    public static createDefault(): AssignmentTreeService {
      return new AssignmentTreeService(umHttp.instance);
    }

    public getAssignmentTreeNodes(): Promise<IAssignmentTreeNodeViewModel[]> {
      return this.httpGet<IAssignmentTreeNodeViewModel[]>('', {
        filterByCustomer: true,
        includeTowers: false
      });
    }
}
