


































// Libraries
import { Component, Vue, VModel, Prop, Watch } from "vue-property-decorator";
// View Models
import { alphabeticSorter } from "@/utils/array-utils";
import { Nil } from "@/utils/types";
import { VariableTreeNode } from "@/view-models/variables";
// Components
import IconButton from "@/components/dsm/IconButton.vue";
import TreeNodeOptions from "@/components/variable-tree/TreeNodeOptions.vue";
import TreeNodeCheckbox from "@/components/variable-tree/TreeNodeCheckbox.vue";
import VisibilityObserver from '@/components/VisibilityObserver.vue';
import Tooltip from '@/components/Tooltip.vue';
import InfiniteLoading from 'vue-infinite-loading';
// Store
import inputsTree from "@/store/inputs-tree";
import eventBus from "@/utils/event-bus";
import app from "@/store/app";

@Component({
  name: 'tree-node',
  components: {
    IconButton,
    TreeNodeOptions,
    TreeNodeCheckbox,
    VisibilityObserver,
    Tooltip,
    InfiniteLoading
  }
})
export default class TreeNode extends Vue {
  // VUE.JS Props
  @VModel({ default: null })
  public node!: Nil<VariableTreeNode>;
  @Prop({ default: false, type: Boolean })
  public rootNode: boolean;
  // VUEX
  // Properties
  public visible: boolean = false;
  public isHovered: boolean = false;
  public infiniteId: number = 0;
  public childNodes: VariableTreeNode[] = [];
  public loadedChildNodes: Array<VariableTreeNode> = [];
  // Fields
  // Getters
  public get isSelected(): boolean {
    return inputsTree.selectedNodesRecord[this.node.key] != null;
  }
  public get isOpen(): boolean {
    return inputsTree.openNodesRecord[this.node.key] != null;
  }
  public get isInMergePhase(): boolean {
    return inputsTree.inMergePhase;
  }
  public get customVariableValidation(): boolean {
    return !app.currentUser.isCustomerUser;
  }
  public getChildNodes(): VariableTreeNode[] {
    const alphaSorter = alphabeticSorter<VariableTreeNode>((node) => node.name);
    const nodes: VariableTreeNode[] = [];
    const leaves: VariableTreeNode[] = [];
    for (const child of inputsTree.childNodesByParentNode(this.node, true) as unknown as VariableTreeNode[] ?? []) {
      if (child.isLeaf) {
        leaves.push(child);
      } else {
        nodes.push(child);
      }
    }
    nodes.sort(alphaSorter);
    leaves.sort(alphaSorter);

    return [...nodes, ...leaves];
  }
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  private mounted(): void {
    this.childNodes = this.getChildNodes();
  }
  // private beforeUpdate(): void {}
  // private updated(): void {}
  // private beforeDestroy(): void {}
  // private destroyed(): void {}
  // Private Methods
  // Helper Methods
  public loadMoreChildNodes(state: any): void {
    if (this.loadedChildNodes.length !== this.childNodes.length) {
      const start = this.loadedChildNodes.length;
      let end = start + 20;
      // eslint-disable-next-line keyword-spacing
      if (end > this.childNodes.length) {
        end = this.childNodes.length;
      }
      for (let i = start; i < end; i++) {
        this.loadedChildNodes.push(this.childNodes[i]);
      }
      state.loaded();
    } else {
      state.complete();
    }
  }
  // Event Methods
  public selectOuterNode(): void {
    if (this.node.isLeaf) {
      if (!this.isSelected && this.customVariableValidation) {
        eventBus.$emit("selected-node", this.node);
      }
      inputsTree.toggleSelectNode([this.node, false]);
    } else {
      inputsTree.toggleNodeIsOpen(this.node);
    }
  }

  // Watchers
  @Watch('isOpen')
  private isOpenChange(open: boolean): void {
    if (!open) {
      this.loadedChildNodes = [];
      this.infiniteId++;
    }
  }
  // Emitters
}
