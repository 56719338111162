













































































































































































// Libraries
import { Component, Prop, Emit, Watch, Ref, Mixins } from 'vue-property-decorator';
import { alphabeticSorter } from '@/utils/array-utils';
import { getDefaultUoM } from '@/utils/uom/uom-helper';
// View Models
import {
  VariableTreeNode,
  IUpdateVariableViewModel,
  IDeleteVariableViewModel
  } from '@/view-models/variables';
import { AccessSettingEnum, MeasurementTypesEnum, TreeFilterEnum, UnitOfMeasurementEnum, VariableMergeActionEnum } from '@/enums/variables';
import type { ISelectOption } from '@/view-models/common';
// Components
import SelectDropdown from '@/components/common/SelectDropdown.vue';
import MergeHistoryModal from '@/components/modals/MergeHistoryModal.vue';
import editor from '@/store/editor';
import inputsTree from '@/store/inputs-tree';
import app from '@/store/app';
import { PermissionsEnum } from '@/enums/permissions';
import { TranslateResult } from 'vue-i18n';
import CalculatorImplementer from '@/components/calculator/CalculatorImplementer.vue';
import Tooltip from '@/components/Tooltip.vue';
// Store
import VariableEditorStore from '@/store/editor';
import VariableEditorService from '@/services/variable-editor-service';
import { CurrentMode } from '@/enums/current-mode';
import eventBus from '@/utils/event-bus';
import SaveConfirmationModal from '@/components/modals/SaveConfirmationModal.vue';
import { VariableStatus } from '@/enums/variable-status';
import ValidateMixin from './mixins/vee-validate';
import { isStringEmpty } from '@/utils/string';

@Component({
  name: 'selected-variable-pane',
  components: {
    SelectDropdown,
    MergeHistoryModal,
    CalculatorImplementer,
    SaveConfirmationModal,
    Tooltip
  }
})
export default class SelectedVariablePane extends Mixins(ValidateMixin) {
  // VUE.JS Props
  @Prop({ required: true })
  public value: VariableTreeNode;
  @Prop({ required: true })
  public isSaving: boolean;
   @Prop({ required: true, default: false })
  public customVariable: boolean;

  public variableEditorStore = VariableEditorStore;
  public treeFilterEnum = TreeFilterEnum;
  public modalTitle: string = '';
  public modalContent: string = '';
  public save = false;
  public isWarning = false;
  public isNameMissing = false;
  public isInvalidFormula = false;
  public isFormulaMissing = false;
  public isDeleteActive=false;
  public deletedNode: VariableTreeNode;
  public currentModeEnum = CurrentMode;
  public modalSecondaryContent: string;
  public aggregatedMeasurementTypes = new Set();
  public hasBeenSelected: boolean = false;
  public isEditingTitle: boolean = false;
  private pathDelimiter: string = '/';
  private multiUpdateOnCustomVariable = false;
  // VUEX
  // Refs
  @Ref('mergeHistory')
  public mergeHistoryRef!: MergeHistoryModal;
  @Ref('saveModal')
  public saveModalRef!: SaveConfirmationModal;

  // Properties
  public variableNotes: string = '';

  // Fields
  // Getters
  public get hasFormula(): boolean {
    return editor.formulaJson ? true : false;
  }
  public get defaultAccessSetting(): boolean {
    return this.accessSettings.includes(AccessSettingEnum.Dashboards) ||
           this.accessSettings.includes(AccessSettingEnum.Reports) ||
           this.accessSettings.includes(AccessSettingEnum.All);
  }
  public get currentMode() {
    return editor.currentMode;
  }
  public get show() {
    return this.save;
  }
  public get showCalculator(): boolean {
    return this.value.data.type === TreeFilterEnum.Custom || this.currentMode == CurrentMode.Create;
  }
  public get options(): Record<string, string> {
    const options: Record<string, string> = {};
    Object.keys(AccessSettingEnum).forEach((enumValue) => {
      if (enumValue !== AccessSettingEnum.All) {
        options[enumValue] = this.$t(`home.accessSettingEnum.${enumValue}`).toString();
      }
    });

    return options;
  }

  public get selectedNumericUnitOfMeasure():
    ISelectOption<UnitOfMeasurementEnum> {
      const validUnitOfMeasure = this.numericUnitOfMeasures.map((measure) => measure.value);
      const isValidUnitOfMeasure = validUnitOfMeasure.includes(this.value.data.unitOfMeasure);

      if (isValidUnitOfMeasure) {
        return { value: this.value?.data?.unitOfMeasure, text: this.value?.data?.unitOfMeasure };
      } else {
        this.selectedNumericUnitOfMeasure = this.numericUnitOfMeasures[0];
        return this.numericUnitOfMeasures[0];
      }
    }

  public set selectedNumericUnitOfMeasure(data) {
    this.value.data.unitOfMeasure = data.value;
  }

  public get selectedDisplayedNumericUnitOfMeasure():
    ISelectOption<UnitOfMeasurementEnum> {
      const validUnitOfMeasure = this.numericUnitOfMeasures.map((measure) => measure.value);
      const isValidUnitOfMeasure = validUnitOfMeasure.includes(this.value.data.displayedUnitOfMeasure);

      if (isValidUnitOfMeasure) {
        return { value: this.value?.data?.displayedUnitOfMeasure, text: this.value?.data?.displayedUnitOfMeasure };
      } else {
        this.selectedDisplayedNumericUnitOfMeasure = this.numericUnitOfMeasures[0];
        return this.numericUnitOfMeasures[0];
      }
    }

  public set selectedDisplayedNumericUnitOfMeasure(data) {
    this.value.data.displayedUnitOfMeasure = data.value;
  }

  public get unitOfMeasure(): string {
    if (this.isNumericMeasurementType) {
      return this.selectedNumericUnitOfMeasure.value;
    } else {
      return this.defaultUnitOfMeasurement as string;
    }
  }
  public get displayedUnitOfMeasure(): string {
    if (this.isNumericMeasurementType) {
      return this.selectedDisplayedNumericUnitOfMeasure.value;
    } else {
      // this is null, might change later on if we get to choose it for
      // other unit of measures.
      return UnitOfMeasurementEnum.Default;
    }
  }
  public get isNumericMeasurementType(): boolean {
    return this.value?.data?.measurementType === MeasurementTypesEnum.Numeric;
  }

  public get numericUnitOfMeasures(): ISelectOption<UnitOfMeasurementEnum>[]  {
    return [
      {
        value: UnitOfMeasurementEnum.Fraction,
        text: UnitOfMeasurementEnum.Fraction
      },
      {
        value: UnitOfMeasurementEnum.Percent,
        text: UnitOfMeasurementEnum.Percent
      }
    ];
  }

  public get displayUnitOfMeasure(): UnitOfMeasurementEnum[] {
    return [UnitOfMeasurementEnum.Fraction, UnitOfMeasurementEnum.Percent];
  }

  public get hasVariable(): boolean {
    return this.value?.name != null || editor.currentMode === CurrentMode.Create;
  }

  public get variableText(): string {
    return this.value?.name ?? 'None';
  }

  public get displayName() {
    return this.value?.data?.displayName;
  }
  public set displayName(value) {
    this.value.data.displayName = value;
  }

  public get fullHierarchyPath(): string {
    return inputsTree?.customParentNode?.variablePath + '/' + this.displayName;
  }

  public get hierarchyPath(): string {
    let path = '', full_name = '';
    if (inputsTree?.customParentNode) {
      full_name = inputsTree?.customParentNode?.variablePath + '/' + this.displayName;
    } else {
      full_name = this.displayName;
    }
    const split_path = full_name.split('/');
    if (split_path.length > 4) {
      path = split_path.slice(0,2).join('/')+'/.../'+split_path.slice(split_path.length-2,split_path.length).join('/');
    } else {
      path = full_name;
    }
    return path;
  }

  public set accessSettings(value) {
    this.value.data.accessSettings = value;
  }

  public get accessSettings() {
    return this.value?.data?.accessSettings ?? [];
  }

  public get canEdit(): boolean {
    return app.userPermissions.some((permission) => permission === PermissionsEnum.ManageVariableEditor);
  }

  public get selectedMeasurement(): ISelectOption<MeasurementTypesEnum> {
    let type = this.value?.data?.measurementType;
    const tempType = type;
    if (type != null) {
      if (!this.hasBeenSelected) {
        if (inputsTree.selectedNodeKeys.length !== 1) {
          // throw all measurementTypes into Set above
          this.aggregatedMeasurementTypes =
            new Set(inputsTree.selectedNodeKeys.map(k => inputsTree.nodesTable[k].data.measurementType));

          // more than one type selected, make dropdown signify Multiple attributes selected
          if (this.aggregatedMeasurementTypes.size > 1) {
            type = MeasurementTypesEnum.Multiple;
            this.hasBeenSelected = true;
          }
        }
      }
      // reset hasBeenSelected, since we only care about instances when more than one items have been selected
      if (inputsTree.selectedNodeKeys.length <= 1) {
        this.hasBeenSelected = false;
      }
    }

    // ensure "Multiple" selection persists with multiple selections
    if (inputsTree.selectedNodeKeys.length > 1) {
      type = MeasurementTypesEnum.Multiple;
    }

    // Allow setting of multiple item measurement types by checking if node is clean
    if (!this.selectedNodeIsClean || !this.numericNodeIsClean) {
      type = tempType;
    }

    return {
      value: type,
      text: this.$t(`home.measurementTypes.${type}`).toString()
    };
  }

  public set selectedMeasurement(value: ISelectOption<MeasurementTypesEnum>) {
    if (this.value.data?.measurementType != null) {
      this.value.data.measurementType = value?.value ?? MeasurementTypesEnum.Unknown;
    }
  }

  public get measurementsOptions(): ISelectOption<MeasurementTypesEnum>[] {
    const sorted = Object.values(MeasurementTypesEnum)
      .filter(this.availableOptions)
      .sort(alphabeticSorter())
      .map((type) => ({
        value: type,
        text: this.$t(`home.measurementTypes.${type}`).toString()
      }));

    sorted.unshift({
      value: MeasurementTypesEnum.Unknown,
      text: this.$t(`home.measurementTypes.${MeasurementTypesEnum.Unknown}`).toString()
    });

    return sorted;
  }
  public get variablePathNodes(): string[] {
    return this.value ? this.value.data.variablePath?.split(this.pathDelimiter) : [];
  }
  public get variablePathDisplay(): string {
    const localVariablePathNodes: string[] = this.variablePathNodes?.slice(1, this.variablePathNodes.length - 1);
    const displayName = this.value ? this.value.data.displayName : '';
    return Array.isArray(localVariablePathNodes) ? localVariablePathNodes.join(` ${this.pathDelimiter} `) + ' / ' + displayName : null;
  }

  public availableOptions(option: string) {
    if (editor.currentMode === CurrentMode.Create) {
     return option === MeasurementTypesEnum.Numeric;
    } else {
     return (option !== MeasurementTypesEnum.Unknown) && (option !== MeasurementTypesEnum.Multiple);
    }
  }

  public get defaultUnitOfMeasurement(): string | TranslateResult {
    if (this.selectedMeasurement.value) {
      return this.$t(`home.uoms.${getDefaultUoM(this.selectedMeasurement.value)}`);
    }
    return this.$t('home.uoms.Default');
  }

  public get isInMergePhase(): boolean {
    return this.value?.history != null;
  }

  public get selectedNodeIsClean(): boolean {
    return inputsTree.selectedNodeIsClean;
  }

  public numericNodeIsClean = true;

  public get numericNodeIsCleanValue() {
    return this.numericNodeIsClean;
  }

  public get disableSave(): boolean {
    if (this.isSaving) {
      return true;
    }
    if (!this.selectedNodeIsClean) {
      return false;
    }
    if (this.currentMode === CurrentMode.Edit && this.customVariable) {
      return editor.originalFormulaJson == editor.formulaJson;
    }
    return true;
  }

  public get singleNodeSelection(): boolean {
    return this.selectionCount <= 1;
  }
  public get selectionCount(): number {
    return inputsTree.selectedNodeKeys.length;
  }
  public get variableNames(): string[] {
    return inputsTree.selectedNodeKeys.map((nodeKey) => {
      return inputsTree.nodesTable[nodeKey].name;
    }).splice(0, 10);
  }
  // created(): void {}
  public beforeMount(): void {
    this.variableNotes = this.value?.data?.notes;
    this.valueChanged(this.value);
  }
  // public mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  public deactivated(): void {
    this.variableNotes = '';
  }
  // beforeDestroy(): void {}
  public destroyed(): void {
    this.variableNotes = '';
  }
  // Private Methods
  // Helper Methods
  // Event Methods
  public showFocus() {
    eventBus.$emit('calculator-clear-focus', true);
  }
  public showConfirmation() {
   if (this.customVariable) {
      const displayName = this.value?.data?.displayName;
      if (!this.value?.data?.displayName || isStringEmpty(displayName) || this.value?.data?.displayName === 'Untitled Variable') {
        this.save = true;
        this.isNameMissing = true;
        this.isWarning = true;
        this.isInvalidFormula = false;
        this.isFormulaMissing = false;
        this.modalTitle = this.$t('home.variables.missingName') as string;
        this.modalContent =this.$t('home.variables.missingNameInfo') as string;
        return;
      }

      if (this.currentMode === CurrentMode.Create) {
        this.save = true;
        this.modalSecondaryContent='';
        eventBus.$emit('update-formula', true);
        this.showDynamicContent();
        this.showSaveConfirmationModal();
        return;
      }

      if (this.currentMode === CurrentMode.Edit ) {
        this.save = true;
        this.modalSecondaryContent='';
        eventBus.$emit('update-formula', true);
        this.showValidationForEditMode();
        this.showSaveConfirmationModal();
        return;
      }
   } else {
     this.saveVariable();
   }
  }
  public showValidationForEditMode() {
    this.isWarning = false;
    this.isNameMissing = false;
    this.isInvalidFormula = false;
    this.isFormulaMissing = false;
    this.modalTitle = this.$t('home.variables.saveCustomVariable') as string;
    this.modalContent = this.$t('home.variables.saveConfirmationContent') as string;

    if (!this.value?.data?.displayName) {
      this.isNameMissing = true;
      this.isWarning = true;
      this.modalTitle = this.$t('home.variables.missingName') as string;
      this.modalContent =this.$t('home.variables.missingNameInfo') as string;
    }
  }
  public showDynamicContent(apiError?: {isError: boolean, message: string}) {
    if (apiError?.isError && apiError?.message !== 'Some of the variables are non-numeric') {
      this.isWarning = true;
      this.isNameMissing = false;
      this.isInvalidFormula = true;
      this.isFormulaMissing = false;
      this.modalTitle = this.$t('calculator.formulaInvalid') as string;
      this.modalContent = this.$t('calculator.formulaInvalidInfo') as string;
    } else if (this.hasFormula && this.value?.data?.displayName) {
      this.isWarning = false;
      this.isNameMissing = false;
      this.isInvalidFormula = false;
      this.isFormulaMissing = false;
      this.modalTitle = this.$t('home.variables.saveCustomVariable') as string;
      this.modalContent = this.$t('home.variables.saveConfirmationContent') as string;
    } else if (!this.value?.data?.displayName) {
      this.isNameMissing = true;
      this.isWarning = true;
      this.isInvalidFormula = false;
      this.isFormulaMissing = false;
      this.modalTitle = this.$t('home.variables.missingName') as string;
      this.modalContent =this.$t('home.variables.missingNameInfo') as string;
    } else if (!this.hasFormula && this.currentMode !== CurrentMode.Edit) {
      this.isNameMissing = false;
      this.isWarning = true;
      this.isInvalidFormula = false;
      this.isFormulaMissing = true;
      this.modalTitle = this.$t('calculator.missingFormula') as string;
      this.modalContent = this.$t('calculator.missingFormulaInfo') as string;
    }
  }
  public mounted() {
    eventBus.$on('updateMsg', this.showDynamicContent);
    eventBus.$on('delete-node', this.deleteNode);
    eventBus.$on('delete-Error-Msg', this.deleteErrorMsg);
  }
  public deleteNode(node: VariableTreeNode):void{
    this.deletedNode = node;
    const payload: IDeleteVariableViewModel = {
      assetKey: editor.currentAsset.key,
      nodeKey: this.deletedNode?.data?.nodeKey ,
      dataRefs: this.deletedNode?.data?.dataRefs? this.deletedNode.data.dataRefs : [''],
      variableStatus:VariableStatus.Inactive,
    };
    VariableEditorStore.deleteVariableValidation(payload).then((resp) => {
      if (resp.data.length){
        this.deleteErrorMsg(resp.data);
      } else {
        this.save=true;
        this.isNameMissing=false;
        this.isWarning=false;
        this.isInvalidFormula=false;
        this.isFormulaMissing = false;
        this.modalTitle="Delete the Variable?";
        this.modalSecondaryContent='';
        this.modalContent="You will not be able to recover a deleted variable";
        this.isDeleteActive=true;
        this.deletedNode=node;
        this.showSaveConfirmationModal();
      }
    });
  }

  public deleteErrorMsg(value: any[]): void {
    const list: string[] = value.map((v) => `${v}`);
    this.save = true;
    this.isNameMissing = false;
    this.isWarning = true;
    this.isInvalidFormula = false;
    this.isFormulaMissing = false;
    this.modalTitle = `This variable can not be deleted`;
    this.modalSecondaryContent = `These variables are: ${list}`;
    this.modalContent = `This variable can not be deleted because it is being referenced by another custom variable. Variables that reference this variable must be deleted before the current variable deleted.`;
    this.isDeleteActive = false;
    this.showSaveConfirmationModal();
  }
  public addFormula(): void {
    const obj = this.$refs.selectedPane as HTMLElement;
    obj.scrollTop = obj.scrollHeight;
    eventBus.$emit('calculator-show-focus', true);
  }
  public addName(): void {
    const obj = this.$refs.selectedPane as HTMLElement;
    obj.scrollTop = 0;
    const inputBox = this.$refs.variableNameInput as HTMLElement;
    inputBox.focus();
  }
  public deleteVariable(): void {
    const payload: IDeleteVariableViewModel = {
      assetKey: editor.currentAsset.key,
      nodeKey: this.deletedNode?.data?.nodeKey ,
      dataRefs: this.deletedNode?.data?.dataRefs? this.deletedNode.data.dataRefs : [''],
      variableStatus:VariableStatus.Inactive,
    };
    this.emitDelete(payload);
  }
  public async saveVariable(): Promise<any> {
    if (this.singleNodeSelection) {
      // do the normal thang
      let payload: IUpdateVariableViewModel;
      if (this.customVariable) {
        payload = {
          assetKey: editor.currentAsset.key,
          nodeKey: this.value.data.nodeKey ? this.value.data.nodeKey : inputsTree.customParentNode.key.replace('-data', ''),
          dataRefs: this.value?.data?.dataRefs? this.value?.data?.dataRefs : [''],
          accessSettings: this.value?.data?.accessSettings,
          newDisplayName: this.value?.data?.displayName,
          measurementType: this.value?.data?.measurementType,
          mergeAction: VariableMergeActionEnum.None,
          formulaJson: this.variableEditorStore.formulaJson ? this.variableEditorStore.formulaJson : this.value?.data?.formulaJson ? this.value?.data?.formulaJson : '',
          type: TreeFilterEnum.Custom,
          actionType: this.currentMode,
          notes: this.value?.data?.notes,
          unitOfMeasure: this.unitOfMeasure ?? UnitOfMeasurementEnum.Default,
          displayedUnitOfMeasure: this.displayedUnitOfMeasure ?? UnitOfMeasurementEnum.Default,
        };
      } else {
        payload = {
          assetKey: editor.currentAsset.key,
          nodeKey: this.value?.data?.nodeKey,
          dataRefs: this.value?.data?.dataRefs,
          accessSettings: this.value?.data?.accessSettings,
          newDisplayName: this.value?.data?.displayName,
          measurementType: this.value?.data?.measurementType,
          mergeAction: VariableMergeActionEnum.None,
          notes: this.value?.data?.notes,
          unitOfMeasure: this.unitOfMeasure ?? UnitOfMeasurementEnum.Default,
          displayedUnitOfMeasure: this.displayedUnitOfMeasure ?? UnitOfMeasurementEnum.Default,
        };
      }
      this.emitSave(payload);
    } else {
      // update multiple variables
      const aggregatedPatchRequests = [];

      // depending on aggregatedMeasurementTypes, adjust payload to include measurement types (or not)
      for (const selectedNode of inputsTree.selectedNodeKeys) {
        const node = inputsTree.nodeByKey(selectedNode, inputsTree.treeHelper.allNodes);
        const query = {
          dataRef: node.data.dataRefs[0],
          nodeKey: node.data.nodeKey,
          assetKey: editor.currentAsset.key
        };
        const payload: any = [
          {
            value: this.value?.data?.accessSettings,
            path: '/accessSettings',
            op: 'replace'
          },
          {
            value: this.value?.data?.measurementType,
            path: '/measurementType',
            op: 'replace'
          },
          {
            value: this.value?.data?.displayedUnitOfMeasure,
            path: '/displayedUnitOfMeasure',
            op: 'replace'
          },
             {
            value: this.value?.data?.unitOfMeasure,
            path: '/unitOfMeasure',
            op: 'replace'
          }
        ];
        if (node.data.formula === null && node.data.formulaJson === null) {
          aggregatedPatchRequests.push({ query, payload, node });
        } else {
          this.multiUpdateOnCustomVariable = true;
        }
      }

      // Send a PATCH request for each selected variable
      for (const request of aggregatedPatchRequests) {
        request.node.data.accessSettings = this.value?.data?.accessSettings;
        request.node.data.measurementType = this.value?.data?.measurementType;
        request.node.data.unitOfMeasure = this.value?.data.unitOfMeasure;
        request.node.data.displayedUnitOfMeasure = this.value?.data.displayedUnitOfMeasure;
        await VariableEditorService.createDefault().patchSaveVariable(request.query, request.payload);
        inputsTree.updateVariableNode({ variable: request.node.data });
      }

      if (this.multiUpdateOnCustomVariable) {
        this.$bvToast.show('multi-variable-edit-error');
      }
    }
    this.numericNodeIsClean = true;
    this.cancel();
  }

  public showMergeHistoryModal(): void {
    this.mergeHistoryRef.showMergeModal();
  }
  public showSaveConfirmationModal(): void {
    if (this.saveModalRef) {
      this.saveModalRef.showModalPopup();
    }
  }

  public toggleAccessSettings(toggle: boolean) {
     if (toggle) {
      this.value.data.accessSettings = [AccessSettingEnum.Dashboards, AccessSettingEnum.Reports];
    } else {
      this.value.data.accessSettings = [];
    }
  }

  // Event Methods
  // Watchers
  @Watch('value')
  public valueChanged(newValue: VariableTreeNode) {
    if (this.value) {
      this.value.data = newValue?.data == null ? null : Object.assign({}, newValue.data);
      this.variableNotes = newValue?.data?.notes ?? '';
      editor.setFormulaJson(newValue?.data?.formulaJson ?? editor.formulaJson);
      editor.setOriginalFormulaJson(newValue?.data?.formulaJson ?? editor.originalFormulaJson);
    }
  }

  @Watch('variableNotes')
  public variableNotesChanged(notes: string) {
    if (this.value?.data != null) {
      this.value.data.notes = notes ?? '';
    }
  }

  // Emitters
  @Emit('save')
  public emitSave(unused: IUpdateVariableViewModel) {}

  @Emit('save-multiple')
  public emitSaveMultiple(unused: IUpdateVariableViewModel) {}

  // Emitters
  @Emit('delete')
  public emitDelete(unused: IDeleteVariableViewModel) {
    this.isDeleteActive = false;
  }

  @Emit('cancel')
  public cancel() {
    this.numericNodeIsClean = true;
    this.multiUpdateOnCustomVariable = false;
    eventBus.$emit('calculator-clear-formula');
  }
}
