import { IPortalAppSdk } from '../../../CommonLibrary/models/portal-parent-sdk';
import { ensureTrailing } from './string';
export * from '../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus() {
  return (window as any).eftEventBus as Vue;
}

export function inParentApp() {
  return parentEventBus() != null;
}

export const APP_TAG = 'variable-editor' as const;
const APP_ROUTE_BASE = '/utilities/ember/variable-editor/';

export function appRouteBase() {
  return inParentApp() ? APP_ROUTE_BASE : '/';
}

export function findAppBaseUrl() {
  const url = process.env.VUE_APP_BASE_URL ?? findAppScriptSourceOrigin();
  return ensureTrailing(url, '/');
}

export function findAppScriptSourceOrigin() {
  const el: HTMLScriptElement = document.getElementById(`${APP_TAG}-script`) as any;
  const url = ensureTrailing(el == null ? '' : new URL(el.src).origin, '/');
  return url;
}

export function getParentAppSdk(): IPortalAppSdk {
  const sdk: IPortalAppSdk = document.getElementById(APP_TAG) as any;
  return sdk;
}
