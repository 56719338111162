import { authGuard } from '@/utils/auth';
import VueRouter from 'vue-router';
import HomePage from '@/pages/Home.vue';
import Vue from 'vue';
import { BaseProvider } from '@/utils/providers/base-provider';
import { appRouteBase } from '@/utils/from-parent';
import appStore from '@/store/app';

Vue.use(VueRouter);

export enum PagesEnum {
  home = 'HomePage'
}

const routes = [
  { path: '/', component: HomePage, name: PagesEnum.home },
];

class RouterProvider extends BaseProvider<VueRouter> {
  constructor() {
    super('Router');
    this.instance = new VueRouter({
      mode: 'history',
      base: appRouteBase(),
      routes,
    });
  }

  public init(): void {
    this.instance.beforeEach(authGuard);
    this.instance.afterEach(() => {
      appStore.setIsLoading(false);
    });
  }
}

export default new RouterProvider();
