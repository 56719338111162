



// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
// View Models
// Components
// Services
@Component({
  name: 'calc-data-part-constant'
})
export default class CalcDataPartConstant extends Vue {
  // VUE.JS Props
  @Prop()
  public part: object[];
  // VUEX

  // Properties
  // Fields

  // Getters

  // Lifecycle Handlers

  // Helper Methods

  // Event Methods

  // Watchers

  // Emitters
}
