import { Nil } from '@/utils/types';

export interface IEnvironmentVariables {
  baseApiUrl: string;
  localizationApiUrl: string;
  envId: string;
  authClientID: string;
  authDomain: string;
  authAudience: string;
  authErrorRedirect: {};
  childAppDomain: string;
}

export interface IPortalEntityViewModel {
  key: string;
  name: string;
  orgKey?: string;
  createdAt?: string;
  createdBy?: string;
  lastModifiedAt?: string;
  lastModifiedBy?: Nil<string>;
  currentError?: string;
}

export interface IErrorBusMessage {
  errorTag: string;
  error: any;
  sendLog: boolean; // default true,
  showMessage: boolean; // default true
}

export enum EntityType {
  Asset = 'Asset',
  AssetGrouping = 'AssetGrouping',
  BurnerGrouping = 'BurnerGrouping',
  Customer = 'Customer',
  CustomerSite = 'CustomerSite',
  Dashboard = 'Dashboard',
  Heater = 'Heater',
  KESCompany = 'KESCompany',
  Organization = 'Organization',
  Product = 'Product',
  ProductSubscription = 'ProductSubscription',
  Report = 'Report',
  ReportInput = 'ReportInput',
  Tower = 'Tower',
  User = 'User',
  UserAssignment = 'UserAssignment',
  UserFavorite = 'UserFavorite',
  UserRole = 'UserRole',
  Unknown = 'Unknown'
}
