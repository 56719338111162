



























// Libraries
import { Component, Vue, Emit } from 'vue-property-decorator';
// View Models
// Components
import { elementId } from '@/utils/component-utils';
import MergeHistoriesPane from '@/components/MergeHistoriesPane.vue';
import { VariableTreeNode } from '@/view-models/variables';
import inputsTree from '@/store/inputs-tree';
// Stores

@Component({
  name: 'merge-history-modal',
  components: {
    MergeHistoriesPane
  }
})
export default class MergeHistoryModal extends Vue {
  // VUE.JS Props
  // VUEX
  // Properties
  public showModal: boolean = false;
  public id: string = 'merge-history-modal';
  // Fields
  // Getters

  public get variables(): VariableTreeNode[] {
    const variables: VariableTreeNode[] = [];
    if (this.selectedVariable != null) {
      variables.push(this.selectedVariable);
    }
    return variables.length > 0 ? variables : [];
  }

  public get selectedVariable(): VariableTreeNode {
    return inputsTree.selectedNode;
  }

  public elementId: (
    entityType: string,
    propertyOrActionOrInputName: string,
    entityKey?: string
  ) => string = elementId;
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // private mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  public showMergeModal(): void {
    this.showModal = true;
  }
  public hideModal() {
    this.showModal = false;
  }
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('save')
  public save() {}
  @Emit('discard')
  public discard() {}
  @Emit('hidden')
  public modalClosed() {}
}
