








// Libraries
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// View Models
// Components
// Store

type Placement = 'top' | 'right' | 'bottom' | 'left';

@Component({
  name: 'tooltip'
})
export default class Tooltip extends Vue {
  // VUE.JS Props
  @Prop({ default: null })
  public title: string | null;
  @Prop({ default: 'top' })
  private placement: Placement;
  @Prop({ default: null })
  private targetNode: HTMLElement | null;
  @Prop({ default: 200 })
  public transition: number | null;
  @Prop({ default: 200 })
  public delay: number;
  // VUEX
  // Properties
  private localTarget: HTMLElement | null = null;
  public localPlacement: Placement = 'top';
  public isVisible: boolean = false;
  public posLeft: number = 0;
  public posTop: number = 0;
  public arrowPosLeft: number = 0;
  public arrowPosTop: number = 0;
  private mutationObserver: MutationObserver | null = null;
  // Fields
  // Getters
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  private mounted(): void {
    this.localPlacement = this.placement;

    if (this.targetNode != null) {
      this.localTarget = this.targetNode;
    } else {
      this.localTarget = this.$el.parentElement;
    }

    if (this.localTarget != null) {
      this.localTarget.addEventListener('mouseenter', this.makeVisible);
      this.localTarget.addEventListener('mouseleave', this.makeHidden);
    }

    this.observeSlotMutations();
  }
  // private beforeUpdate(): void {}
  // private updated(): void {}
  private beforeDestroy(): void {
    if (this.localTarget != null) {
      this.localTarget.removeEventListener('mouseenter', this.makeVisible);
      this.localTarget.removeEventListener('mouseleave', this.makeHidden);
    }

    if (this.mutationObserver != null) {
      this.mutationObserver.disconnect();
    }
  }
  // private destroyed(): void {}
  // Private Methods
  private observeSlotMutations(): void {
    const mutationObserverOptions: MutationObserverInit = {
      childList: true,
      attributes: false
    };

    if (this.$refs.tooltipInner != null) {
      this.mutationObserver = new MutationObserver(this.mutationCallback);
      this.mutationObserver.observe(this.$refs.tooltipInner as HTMLElement, mutationObserverOptions);
    }
  }
  private tooltipPlacementFallback(targetRect: DOMRect, tooltipRect: DOMRect, buffer: number): void {
    switch (this.localPlacement) {
      case 'left':
        if (targetRect.left - tooltipRect.width < buffer) {
          this.localPlacement = 'right';
        }
        break;
      case 'right':
        if (targetRect.left + targetRect.width + tooltipRect.width > document.documentElement.clientWidth) {
          this.localPlacement = 'left';
        }
        break;
      case 'top':
        if (targetRect.top - tooltipRect.height < buffer) {
          this.localPlacement = 'bottom';
        }
        break;
      case 'bottom':
      default:
        if (targetRect.top + targetRect.height + tooltipRect.height > document.documentElement.clientHeight) {
          this.localPlacement = 'top';
        }
        break;
    }
  }
  private tooltipGetPositions(targetRect: DOMRect, tooltipRect: DOMRect, buffer: number): void {
    const arrowLength: number = 20;

    switch (this.localPlacement) {
      case 'left':
      case 'right':
        this.arrowPosTop = tooltipRect.height / 2 - arrowLength / 2;
        this.posTop = targetRect.top - tooltipRect.height / 2 + targetRect.height / 2;

        if (this.posTop < buffer) {
          this.arrowPosTop += this.posTop;
          this.posTop = buffer;
        } else if (this.posTop + tooltipRect.height > document.documentElement.clientHeight) {
          this.arrowPosTop += Math.abs(this.posTop + tooltipRect.height - document.documentElement.clientHeight);
          this.posTop = document.documentElement.clientHeight - tooltipRect.height - buffer;
        }

        if (this.localPlacement === 'left') {
          this.posLeft = targetRect.left - tooltipRect.width;
        } else {
          this.posLeft = targetRect.left + targetRect.width;
        }

        break;
      case 'top':
      case 'bottom':
      default:
        this.arrowPosLeft = tooltipRect.width / 2 - arrowLength / 2;
        this.posLeft = targetRect.left - tooltipRect.width / 2 + targetRect.width / 2;

        if (this.posLeft < buffer) {
          this.arrowPosLeft += this.posLeft;
          this.posLeft = buffer;
        } else if (this.posLeft + tooltipRect.width > document.documentElement.clientWidth) {
          this.arrowPosLeft += Math.abs(this.posLeft + tooltipRect.width - document.documentElement.clientWidth);
          this.posLeft = document.documentElement.clientWidth - tooltipRect.width - buffer;
        }

        if (this.localPlacement === 'top') {
          this.posTop = targetRect.top - tooltipRect.height;
        } else {
          this.posTop = targetRect.top + targetRect.height;
        }

        break;
    }
  }
  private setTooltipPosition(): void {
    const tooltipEl: HTMLElement = this.$refs.tooltip as HTMLElement;

    if (tooltipEl != null) {
      const tooltipRect: DOMRect = tooltipEl.getBoundingClientRect();
      const targetRect: DOMRect = this.localTarget.getBoundingClientRect();
      const buffer: number = 4;

      this.tooltipPlacementFallback(targetRect, tooltipRect, buffer);
      this.tooltipGetPositions(targetRect, tooltipRect, buffer);
    }
  }
  // Helper Methods
  // Event Methods
  private makeVisible(): void {
    this.isVisible = true;
  }
  private makeHidden(): void {
    this.isVisible = false;
  }
  private mutationCallback(mutationList: MutationRecord[]): void {
    this.setTooltipPosition();
  }
  // Watchers
  @Watch('isVisible')
  private visibleChange(): void {
    if (this.isVisible) {
      this.setTooltipPosition();
    }
  }
  @Watch('title')
  private titleChange(newTitle: string, oldTitle: string): void {
    if (newTitle != oldTitle) {
      this.setTooltipPosition();
    }
  }
  // Emitters
}
