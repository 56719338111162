





// Libraries
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
// View Models
// Components

// Store
@Component({
  name: 'visibility-observer'
})
export default class VisibilityObserver extends Vue {
  // VUE.JS Props
  @Prop({ default: '0%' })
  private rootMargin: string; // standard param for IntersectionObserver
  @Prop({ default: 0 })
  private threshold: number | number[]; // standard param for IntersectionObserver
  @Prop({ default: 0 })
  private debounceMs: number;
  @Prop({ default: 'div' })
  public rootTagName: string;
  @Prop({ default: null })
  private minHeight: string;
  @Prop({ default: false, type: Boolean })
  private onceVisibleAlwaysVisible: boolean;

  @Ref('observerEl')
  private readonly observerEl: HTMLElement;

  // VUEX
  // Properties
  private intersectionObserver: IntersectionObserver = null;
  private isVisible: boolean = false;
  // Fields
  // Getters
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  // private created(): void {}
  // private beforeMount(): void {}
  private mounted(): void {
    const intersectionObserverOptions: IntersectionObserverInit = {
      root: document as unknown as HTMLElement,
      rootMargin: this.rootMargin,
      threshold: this.threshold
    };

    this.intersectionObserver = new IntersectionObserver(this.intersectionCallback, intersectionObserverOptions);

    this.intersectionObserver.observe(this.observerEl);
    if (this.minHeight != null) {
      this.observerEl.style.minHeight = this.minHeight;
    }
  }
  // private beforeUpdate(): void {}
  // private updated(): void {}
  private beforeDestroy(): void {
    if (this.intersectionObserver != null) {
      this.intersectionObserver.disconnect();
    }
  }
  // private destroyed(): void {}
  // Private Methods
  // Helper Methods
  private intersectionCallback(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      const matchingTarget: any = this.observerEl === entry.target;

      if (matchingTarget != null) {
        if (this.debounceMs === 0) {
            this.updateVisibility(entry);
        } else {
          debounce(() => {
            this.updateVisibility(entry);
          }, this.debounceMs);
        }
      }
    });
  }
  // Event Methods
  private updateVisibility(entry: IntersectionObserverEntry): void {
    const isVisible: boolean = entry.isIntersecting || entry.intersectionRatio > 0;

    if (this.isVisible !== isVisible) {
      this.isVisible = this.onceVisibleAlwaysVisible && this.isVisible ? true : isVisible;
      // @ts-ignore
      this.observerEl.style.contain = this.isVisible ? 'none' : 'content';
      this.emitVisible(this.isVisible);
    }
  }
  // Watchers
  // Emitters
  @Emit('visible')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public emitVisible(visible: boolean): void {}
}
