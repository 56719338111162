















// Libraries
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ParentEvents, configureEventBuses } from '@/utils/event-bus';
import { parentEventBus, appRouteBase } from '@/utils/from-parent';
import router from './router';
import i18n from '@/utils/i18n';
import config from '@/config';
import auth from '@/utils/auth';
import { initializeHttpClients } from '@/services/common/http';
import vuePlugins from '@/plugins';
// View Models
// Components
import Error from './components/modals/Error.vue';
import Loading from './components/common/Loading.vue';
import AppReady from './components/mixins/app-ready';
// Store
import store from '@/store/';
import appStore from '@/store/app';
import editor from '@/store/editor';
import inputsTree from '@/store/inputs-tree';
import { CurrentMode } from './enums/current-mode';

// Initializing the app global services/configs/utilities
// There is an isAppInitialized property on the app store
// to know when the app is ready
(async function initializeApp() {
  appStore.setIsAppInitialized(false);

  // Config
  await config.init();
  // Auth
  await auth.init();
  // Http Client
  initializeHttpClients();
  // Router
  router.init();
  // Vue Plugins
  vuePlugins();
  // Events Buses
  configureEventBuses();
  // User Permissions
  await appStore.getUserPermissions();
  // User Data
  await appStore.getCurrentUser();

  appStore.setIsAppInitialized(true);
})();

@Component({
  name: 'app',
  components: {
    Error,
    Loading
  },
  store,
  router: router.instance,
  i18n
})
export default class App extends Mixins(AppReady) {
  // VUE.JS Props
  // Refs
  // VUEX
  public get isLoading() {
    return appStore.isLoading || !appStore.isAppInitialized;
  }

  public get activeCustomerKey() {
    return appStore.activeCustomerKey;
  }

  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created() {}
  // beforeMount(): void {}
  public mounted() {
    this.initialRoute();
    parentEventBus()?.$on(
      ParentEvents.RouteChange,
      this.handleRouteChange
    );
    parentEventBus()?.$on(
      ParentEvents.ActiveCustomerChanged,
      this.handleActiveCustomerChange
    );
  }
  // ready() {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    appStore.setIsLoading(true);
    parentEventBus()?.$off(
      ParentEvents.RouteChange,
      this.handleRouteChange
    );
    parentEventBus()?.$off(
      ParentEvents.ActiveCustomerChanged,
      this.handleActiveCustomerChange
    );
  }
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private initialRoute() {
    const base = appRouteBase();
    const path = base.slice(0, base.length - 1) + this.$route.path;
    const route = Object.assign({}, this.$route, { path });
    this.handleRouteChange(route);
  }
  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.startsWith(appRouteBase())) {
      appStore.setIsLoading(false);
      let relativeRoute = parentRoute.path.replace(appRouteBase(), '/');
      relativeRoute = relativeRoute.replace(/\/\//g, '/');
      if (relativeRoute !== this.$route.path) {
        this.$router.push(relativeRoute);
      }
    }
  }
  private handleActiveCustomerChange(key: string) {
    appStore.setActiveCustomerKey(key);
  }
  // Helper Methods
  // Event Methods
  // Watchers
  @Watch('activeCustomerKey')
  public async activeCustomerKeyChanged() {
    appStore.setIsLoading(true);
    await editor.prepareSitesAndAssets();
    inputsTree.setInMergePhase(false);
    inputsTree.clearNode();
    editor.setCurrentMode(CurrentMode.Edit);
    inputsTree.clearNode();
    appStore.setIsLoading(false);
  }
  // Emitters
}
