











// Libraries
import { Component, Prop, Vue } from 'vue-property-decorator';

// View Models
import { Formula } from '@/components/calculator/classes';
// Components
import Calculator from '@/components/calculator/Calculator.vue';
import CalculatorFormula from '@/components/calculator/sub-components/Formula.vue';

@Component({
  name: 'calculator-implementer',
  components: {
    Calculator,
    CalculatorFormula
  },
})
export default class CalculatorImplementer extends Vue {
  // VUE.JS Props
  // Service
  // VUEX
  // Properties
  public showSaveModal: boolean = false;
  public tempFormula: Formula = null;
  public tempVariableName: string = '';

 // VUE.JS Props
  @Prop({ required: true })
  public value!: string;
  // Fields
  // Getters
  // Lifecycle Handlers
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods

  private resetFormulaTemps(): void {
    this.tempFormula = null;
    this.tempVariableName = '';
  }

  // Helper Methods
  // Event Methods
  public cancelSave(): void {
    this.showSaveModal = false;
    this.resetFormulaTemps();
  }
}
