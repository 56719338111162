























































































// Libraries
import { Component, Vue, Prop, Watch, VModel, Emit } from 'vue-property-decorator';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import { NoCache } from '@/utils/decorators';
import { elementId } from '@/utils/component-utils';
import { Events, ISubscription, subscription } from '@/utils/event-bus';
// View Models
import { Nil } from '@/utils/types';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { VariableTreeNode, VariableTreeNodeData } from '@/view-models/variables';
// Components
import TreeNode from '@/components/variable-tree/TreeNodeOld.vue';
import Loading from '@/components/common/Loading.vue';
import MergeConfirmationModal from '@/components/modals/MergeConfirmationModal.vue';
import { BDropdown } from 'bootstrap-vue';
import ObjectSearch from '@/components/ObjectSearch.vue';
import { CurrentMode } from '@/enums/current-mode';

// Store
import inputsTree from '@/store/inputs-tree';
import { TreeFilterEnum } from '@/enums/variables';
import app from '@/store/app';
import { PermissionsEnum } from '@/enums/permissions';
import editor from '@/store/editor';

@Component({
  name: 'variable-tree',
  components: {
    ObjectSearch,
    TreeNode,
    Loading,
    MergeConfirmationModal,
    CalculatorImplementer: () => import('../calculator/CalculatorImplementer.vue'),
  },
})
export default class VariableTree extends Vue {
  // VUE.JS Props
  @VModel({ default: null })
  public selectedVariable!: Nil<VariableTreeNode>;
  @Prop({ default: null })
  public asset!: Nil<IAssignmentTreeNodeViewModel>;

  public currentModeEnum = CurrentMode;
  public checked: boolean=false;

  // Getters...
  private get isInMergePhase() {
    return inputsTree.inMergePhase;
  }
  public get placeHolder(): string {
    if (inputsTree.selectedNodeKeysForMerge[0]) {
      return inputsTree.nodeByKey(inputsTree.selectedNodeKeysForMerge[0],
        inputsTree.treeHelper.allNodes).data.displayName;
    }
  }
  public get nodesTable() {
    return inputsTree.filteredNodesTable;
  }

  public get searchedNodeTable() {
    return inputsTree.searchedNodesTable;
  }

  public get currentMode(): string {
    return editor.currentMode;
  }

  private get treeHelper(): TreeTraverseHelper<VariableTreeNodeData> {
    return new TreeTraverseHelper<VariableTreeNodeData>(this.nodesTable);
  }

  public get filteredNodesTable() {
    return this.results.length > 0 ? this.searchedNodeTable : this.nodesTable;
  }

  get results(): any[] {
    return inputsTree.searchedNodeKeys || [];
  }

  set results(values: any[]) {
    inputsTree.setSearchedNodeKeys(values?.map(value=>value.key));
    if (values && values.length > 0) {
      inputsTree.openParentNodes(values[0].key);
    }
  }

  // Properties
  public filterDropdownHelper: boolean = false;
  public treeFilterEnum = TreeFilterEnum;
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
  public objectSearchKey = 1;
  public showEmptyResults: boolean = false;
  // Fields
  @NoCache
  private leafSelectedSub: Nil<ISubscription> = null;
  @NoCache
  private nodeToggledSub: Nil<ISubscription> = null;

  // Getters

  public get canConfirmMerge(): boolean {
    if (inputsTree.selectedNodeKeysForMerge != null && inputsTree.selectedNodeKeysForMerge.length) {
      return !!(inputsTree.selectedNodeKeysForMerge.length > 1);
    }
  }

  public get isLoadingTree(): boolean {
    return this.asset == null && inputsTree.loadingNodesTable;
  }
  public get isLoading(): boolean {
    return inputsTree.loadingNodesTable;
  }
  public get hasTree(): boolean {
    return this.asset != null && !inputsTree.loadingNodesTable;
  }
  public get nodesList(): VariableTreeNode[] {
    return this.treeHelper.allNodes;
  }
  public get rootNode(): VariableTreeNode {
    return inputsTree.rootAssetNode;
  }
  public get selectedFilter(): TreeFilterEnum {
    return inputsTree.selectedTreeEnum;
  }
  public get mergeFlag(): boolean {
    return false;
  }
  public get filterTreeEnum(): string[] {
    let filterTypes = Object.keys(this.treeFilterEnum);
    if (!this.mergeFlag) {
      filterTypes = filterTypes.filter((k) => k !== TreeFilterEnum.Merged);
    }

    return filterTypes.filter((type => type !== TreeFilterEnum.Enabled));
  }
  public get canMerge(): boolean {
    return this.mergeFlag
        && app.userPermissions.some((permission) => permission === PermissionsEnum.ManageVariableEditor);
  }
 
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.asset != null) {
      this.fetchVariableTree(this.asset);
    }
    this.leafSelectedSub = subscription(Events.VariableTreeNodeLeafSelected, this.onNodeSelected);
    this.nodeToggledSub = subscription(Events.VariableTreeNodeToggleIsOpen, this.onNodeToggledIsOpen);
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'filter-dropdown' && !this.filterDropdownHelper) {
        this.filterDropdownHelper = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'filter-dropdown' && this.filterDropdownHelper) {
        bvEvent.preventDefault();
        this.filterDropdownHelper = false;
      }
    });
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  public beforeDestroy(): void {
    this.leafSelectedSub.unsubscribe();
    this.nodeToggledSub.unsubscribe();
  }
  // destroyed(): void {}

  // Private Methods
  // Helper Methods
  private async fetchVariableTree(asset: IAssignmentTreeNodeViewModel) {
    await inputsTree.loadTree(asset);
  }
  private resetTree() {
    inputsTree.setNodesTable({});
  }
  public toggleSiteDropdown() {
    const siteDropdown = this.$refs.filterDropdown as BDropdown;
    const isFilterDropdownOpen = siteDropdown.visible;
    if (!isFilterDropdownOpen) {
      siteDropdown.show();
    } else {
      siteDropdown.hide();
    }
    this.filterDropdownHelper = false;
  }

  // Event Methods
  public activateMergePhase(merge: boolean) {
    inputsTree.setInMergePhase(merge);
  }

  public onNodeSelected(unused: VariableTreeNode) {
    // TODO
  }

  public selectFilter(val: TreeFilterEnum) {
    inputsTree.setSelectedTreeFilter(val);
  }

  public onNodeToggledIsOpen(node: VariableTreeNode) {
    inputsTree.toggleNodeIsOpen(node);
  }

  public onMergeConfirmed() {
    this.$bvModal.show('merge-confirmation-modal');
  }

  // Watchers
  @Watch('asset')
  protected async assetChanged(asset: Nil<IAssignmentTreeNodeViewModel>) {
    if (asset == null) {
      this.resetTree();
    } else {
      await this.fetchVariableTree(asset);
    }
  }

  // Emitters
  @Emit('show-unsaved-modal')
  public showUnsavedModal(returnFunction: any) {}

  @Emit('merge-complete')
  public mergeComplete() {}
}
