
























// Libraries
import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import type { BDropdown } from 'bootstrap-vue';
// View Models
import type { Nil } from '@/utils/types';
import type { ISelectOption } from '@/view-models/common';
// Components

@Component({
  name: 'select-dropdown'
})
export default class SelectDropdown<T> extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public id!: string;
  @Prop({ required: true, default: null })
  public value!: Nil<ISelectOption<T>>;
  @Prop({ required: true })
  public options!: ISelectOption<T>[];
  @Prop({ default: '' })
  public placeholder!: string;
  @Prop({ default: '100%' })
  public maxWidth!: string;
  @Prop({ default: false })
  public disabled: boolean;

  // Refs
  @Ref('dropdownRef')
  private dropdownRef: BDropdown;

  // VUEX
  // Properties
  // Fields
  private dropdownStatus: boolean = false;

  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === this.id && !this.dropdownStatus) {
        this.dropdownStatus = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === this.id && this.dropdownStatus) {
        bvEvent.preventDefault();
        this.dropdownStatus = false;
      }
    });
  }

  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public toggleDropdown() {
    if (!this.dropdownRef.visible) {
      this.dropdownRef.show();
    } else {
      this.dropdownRef.hide();
    }
    this.dropdownStatus = false;
  }

  // Watchers
  // Emitters
  @Emit('input')
  public valueChanged(unused: Nil<ISelectOption<T>>) {}
}
