import config from '@/config';
import { Nil } from '@/utils/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class BaseService {
  constructor(
    public readonly http: AxiosInstance
  ) {}

  public async httpGet<T>(uri: string, query?: Record<string, any>, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (headers != null) {
      config.headers = headers;
    }
    if (processQuery(query) != null) {
      config.params = query;
    }
    return (await this.http.get<T>(this.url(uri), config)).data;
  }

  public async httpPost<T>(uri: string, payload?: any, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.http.post<T>(this.url(uri), payload)).data;
  }

  public async httpPut<T>(uri: string, payload?: any, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.http.put<T>(this.url(uri), payload, config)).data;
  }

  public async httpPatch<T>(uri?: string, query?: Record<string, any>, payload?: any): Promise<T> {
    const config: AxiosRequestConfig = {
      params: query || undefined
    };
    return (await this.http.patch<T>(this.url(uri), payload, config)).data;
  }

  public async httpDelete<T>(uri: string, query?: Record<string, any>): Promise<T> {
    return (await this.http.delete<T>(this.url(uri), processQuery(query))).data;
  }

  protected url(uri?: string): string {
    return uri ?? '';
  }
}

function processQuery(query?: Record<string, any>): Nil<AxiosRequestConfig> {
  return query == null ? undefined : { params: query };
}
