export default class HelperMethods {

 /**
  * Generates a random guid string
  * @returns {string}
  */
  public static newGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  /**
  * Adds a CSS class (which is expected to have an animation attached to it) to a HTML element and waits for animation to end before removing the class. Optionally runs a callback method at time of end event.
  * @param className: string
  * @param targetEl: HTMLElement
  * @param callback: Function - default null
  * @returns {Promise(void)}
  */
  public static async runAnimation(
    className: string,
    targetEl: HTMLElement,
    keepClassAfterAnimate: boolean = false
  ): Promise<any> {
    await new Promise((resolve: (value?: {} | PromiseLike<{}>) => void) => {
      targetEl?.addEventListener('animationend', () => {
        if (!keepClassAfterAnimate) {
          targetEl?.classList.remove(className);
        }
        resolve();
      });

      targetEl?.classList.add(className);
    });
  }

  /**
  * Removes an animation class from an HTML element. For cases where the element doesnt hear the end event or if its set to run infinitely.
  * @param className: string
  * @param targetEl: HTMLElement
  * @returns {void}
  */
  public static killAnimation(className: string, targetEl: HTMLElement): void {
    targetEl.classList.remove(className);
  }
}
