




















// Libraries
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IToast } from '@/view-models/common';
import Toast from '@/components/common/Toast.vue';
// View Models
// Components
// Services
// Store

@Component({
  name: 'Toaster',
  components: {
    Toast
  }
})
export default class extends Vue {
// VUE.JS Props
  @Prop({ required: true })
  public name: string;
  @Prop({ default: (): IToast[] => [] })
  public toasts: IToast[];
  @Prop({ default: false })
  public append: boolean;
// VUEX
// Properties
// Fields
// Getters
// Lifecycle Handlers
// beforeCreate(): void {}
// created(): void {}
// beforeMount(): void {}
// mounted(): void {}
// beforeUpdate(): void {}
// updated(): void {}
// beforeDestroy(): void {}
// destroyed(): void {}
// Private Methods
// Helper Methods
// Event Methods
// Watchers
// Emitters
  @Emit('hideToast')
  public hideToast(unused: string) {}
}
