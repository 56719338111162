












































































// Libraries
import { Component, Vue, Prop, Watch, VModel, Emit, Ref } from 'vue-property-decorator';
import { elementId } from '@/utils/component-utils';
import { Events, ISubscription, subscription } from '@/utils/event-bus';
// View Models
import { Nil } from '@/utils/types';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { VariableNodesTable, VariableTreeNode } from '@/view-models/variables';
// Components
import TreeNode from '@/components/variable-tree/TreeNode.vue';
import TreeNodeFlat from '@/components/variable-tree/TreeNodeFlat.vue';
import Loading from '@/components/common/Loading.vue';
import MergeConfirmationModal from '@/components/modals/MergeConfirmationModal.vue';
import VariableCloneModal from '@/components/modals/VariableCloneModal.vue';
import { BDropdown } from 'bootstrap-vue';
import ObjectSearch from '@/components/ObjectSearch.vue';
import { CurrentMode } from '@/enums/current-mode';
import InfiniteLoading from 'vue-infinite-loading';
import Tooltip from '@/components/Tooltip.vue';

// Store
import inputsTree from '@/store/inputs-tree';
import { TreeFilterEnum } from '@/enums/variables';
import app from '@/store/app';
import { PermissionsEnum } from '@/enums/permissions';
import editor from '@/store/editor';
import { isEqual } from 'lodash';

@Component({
  name: 'variable-tree',
  components: {
    ObjectSearch,
    TreeNode,
    TreeNodeFlat,
    InfiniteLoading,
    Loading,
    MergeConfirmationModal,
    VariableCloneModal,
    Tooltip,
    CalculatorImplementer: () => import('../calculator/CalculatorImplementer.vue'),
  },
})
export default class VariableTree extends Vue {
  // VUE.JS Props
  @VModel({ default: null })
  public selectedVariable!: Nil<VariableTreeNode>;
  @Prop({ default: null })
  public asset!: Nil<IAssignmentTreeNodeViewModel>;

  @Ref('cloneModal')
  public cloneModalRef!: VariableCloneModal;

  public currentModeEnum = CurrentMode;
  public checked: boolean = false;
  public filterOptions = [TreeFilterEnum.Enabled, TreeFilterEnum.Custom];
  public showListView: boolean = false;
  public infiniteId: number = 0;
  public loadedLeafNodes: Array<VariableTreeNode> = [];
  private unsavedTriggerSub: Nil<ISubscription> = null;

  // Getters...
  public get searchString(): string {
    return inputsTree.searchString;
  }
  private get isInMergePhase() {
    return inputsTree.inMergePhase;
  }
  public get placeHolder(): string {
    if (inputsTree.selectedNodeKeysForMerge[0]) {
      return inputsTree.nodeByKey(inputsTree.selectedNodeKeysForMerge[0],
        inputsTree.treeHelper.allNodes).data.displayName;
    }
  }
  public get nodesTable(): VariableNodesTable {
    return inputsTree.filteredNodesTable;
  }
  public get searchedNodeTable() {
    return inputsTree.searchResultNodesTable;
  }
  public get selectedNodeNameTable(): Record<string, string> {
    return inputsTree.selectedNodesRecord;
  }

  public get currentMode(): string {
    return editor.currentMode;
  }

  public get filteredNodesTable(): VariableNodesTable {
    return this.results.length > 0 ? this.searchedNodeTable : this.nodesTable;
  }
  public get disabledTreeToggle() {
    return inputsTree.searchString != '' || this.selectedFilters.length > 0;
  }
  public get someNodesOpen(): boolean {
    return Object.keys(inputsTree.openNodesRecord).length > 0;
  }

  get results(): any[] {
    return Object.keys(inputsTree.searchedNodeKeysRecord) || [];
  }

  set results(values: any[]) {
    this.forceListView(values);
    inputsTree.setSearchedNodeKeys(values?.map(value => value.key));
  }

  public get selectedFilters() {
    return inputsTree.selectedTreeEnums;
  }

  public updateFilter(val: TreeFilterEnum[]) {
    this.forceListView(val);
    inputsTree.setSelectedTreeFilters(val);
  }

  public forceListView(val: TreeFilterEnum | any[]) {
    if (val && val.length > 0) {
      this.showListView = true;
    }
  }

  // Properties
  public filterDropdownHelper: boolean = false;
  public treeFilterEnum = TreeFilterEnum;
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
  public objectSearchKey = 1;
  // Fields
  // Getters

  public get canConfirmMerge(): boolean {
    if (inputsTree.selectedNodeKeysForMerge != null && inputsTree.selectedNodeKeysForMerge.length) {
      return !!(inputsTree.selectedNodeKeysForMerge.length > 1);
    }
  }

  public get isLoadingTree(): boolean {
    return this.asset == null && inputsTree.loadingNodesTable;
  }
  public get isLoading(): boolean {
    return inputsTree.loadingNodesTable;
  }
  public get hasTree(): boolean {
    return this.asset != null && !inputsTree.loadingNodesTable;
  }
  public get searchList(): VariableTreeNode[] {
    return inputsTree.treeHelper.allNodes;
  }
  public get sortedFilteredLeafNodes(): VariableTreeNode[] {
    return Object.values(inputsTree.filteredNodesTable)
      .filter(i => i != null && i.isLeaf)
      .sort((a, b) => a.data?.variablePath?.localeCompare(b.data?.variablePath));
  }
  public get assetName(): string {
    return this.rootNode?.name;
  }
  public get rootNode(): VariableTreeNode {
    return inputsTree.rootAssetNode;
  }
  public get rootNodeChildren(): Array<VariableTreeNode> {
    const children: Array<VariableTreeNode> =
      inputsTree.rootAssetNode?.childrenKeys.map(k => inputsTree.nodeByKey(k, inputsTree.treeHelper.allNodes));

    return Array.isArray(children) ? children : [];
  }
  public get selectedFilter(): TreeFilterEnum {
    return inputsTree.selectedTreeEnum;
  }
  public get mergeFlag(): boolean {
    return false;
  }
  public get filterTreeEnum(): string[] {
    let filterTypes = Object.keys(this.treeFilterEnum);
    if (!this.mergeFlag) {
      filterTypes = filterTypes.filter((k) => k !== TreeFilterEnum.Merged);
    }

    return filterTypes;
  }
  public get canMerge(): boolean {
    return this.mergeFlag
      && app.userPermissions.some((permission) => permission === PermissionsEnum.ManageVariableEditor);
  }
  public get canCreateVariables(): boolean {
    return !app.currentUser.isCustomerUser;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.asset != null) {
      this.fetchVariableTree(this.asset);
    }
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'filter-dropdown' && !this.filterDropdownHelper) {
        this.filterDropdownHelper = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'filter-dropdown' && this.filterDropdownHelper) {
        bvEvent.preventDefault();
        this.filterDropdownHelper = false;
      }
    });
    this.unsavedTriggerSub = subscription(Events.VariableTreeTriggerUnsavedChanges, () => {
      if (editor.currentMode !== CurrentMode.Create) {
        this.showUnsavedModal();
      }
    });
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  private beforeDestroy(): void {
    this.unsavedTriggerSub.unsubscribe();
  }
  // destroyed(): void {}

  // Private Methods
  // Helper Methods
  public loadMoreLeafNodes(state: any): void {
    if (this.loadedLeafNodes.length !== this.sortedFilteredLeafNodes.length) {
      const start = this.loadedLeafNodes.length;
      let end = start + 20;
      // eslint-disable-next-line keyword-spacing
      if (end > this.sortedFilteredLeafNodes.length) {
        end = this.sortedFilteredLeafNodes.length;
      }
      for (let i = start; i < end; i++) {
        this.loadedLeafNodes.push(this.sortedFilteredLeafNodes[i]);
      }
      state.loaded();
    } else {
      state.complete();
    }
  }
  private async fetchVariableTree(asset: IAssignmentTreeNodeViewModel) {
    await inputsTree.loadTree(asset);
  }
  public toggleSiteDropdown() {
    const siteDropdown = this.$refs.filterDropdown as BDropdown;
    const isFilterDropdownOpen = siteDropdown.visible;
    if (!isFilterDropdownOpen) {
      siteDropdown.show();
    } else {
      siteDropdown.hide();
    }
    this.filterDropdownHelper = false;
  }

  // Event Methods
  public collapseAll(): void {
    inputsTree.collapseAll();
  }
  public deselectAll(): void {
    inputsTree.deselectNodes(inputsTree.treeHelper.allNodes);
  }
  public selectAll(): void {
    inputsTree.selectNodes(this.sortedFilteredLeafNodes);
  }
  public activateMergePhase(merge: boolean) {
    inputsTree.setInMergePhase(merge);
  }

  public onMergeConfirmed() {
    this.$bvModal.show('merge-confirmation-modal');
  }

  // Watchers
  @Watch('showListView')
  private showListViewChange(showList: boolean): void {
    if (!showList) {
      this.loadedLeafNodes = [];
    }
  }
  @Watch('sortedFilteredLeafNodes')
  private sortedFilteredLeafNodesChange(incoming: VariableTreeNode[], old: VariableTreeNode[]): void {
    if (!isEqual(incoming.map(i => i.key), old.map(i => i.key))) {
      this.loadedLeafNodes = [];
      this.infiniteId++;
    }
  }
  @Watch('asset')
  protected async assetChanged(asset: Nil<IAssignmentTreeNodeViewModel>) {
    this.showListView = false;
    inputsTree.resetState();

    if (asset != null) {
      await this.fetchVariableTree(asset);
    }
  }

  public openCopyVariableModal() {
    this.cloneModalRef.show();
  }

  // Emitters
  @Emit('show-unsaved-modal')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showUnsavedModal(returnFunction?: any) { }

  @Emit('merge-complete')
  public mergeComplete() { }
}
