


































































































// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataPartEnum, FunctionEnum, CountDirectionEnum } from '@/enums/calculator-enums';
// View Models
// Components
// Services
@Component({
  components: {
    CalcDataPart: () => import('@/components/calculator/sub-components/DataPart.vue')
  },
  name: 'calc-data-part-function'
})
export default class CalcDataPartFunction extends Vue {
  // VUE.JS Props
  @Prop()
  public part: object[];
  // VUEX

  // Properties
  public PartEnums = DataPartEnum;
  public FunctionEnum = FunctionEnum;
  public CountDirectionEnum = CountDirectionEnum;
  public selectWidth = 0;
  // Fields

  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  private mounted(): void {
    this.getSelectWidth();
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}

  // Helper Methods
  private getSelectWidth() {
    Vue.nextTick().then(() => {
      //this.selectWidth = this.$refs.dropdownSpan ? (this.$refs.dropdownSpan as HTMLElement).offsetWidth + 35 : 0;
    });
  }
  // Event Methods

  // Watchers
  // Emitters
}
