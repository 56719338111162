








































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import inputsTree from '@/store/inputs-tree';

Vue.use(BootstrapVue);

@Component({
  name: 'merge-confirmation-modal',
})
export default class MergeConfirmationModal extends Vue {
  @Prop({ required: true  })
  private placeHolder: string;
  public isLoading: boolean = false;
  // Event Methods...
  private cancel(): void {
    this.$bvModal.hide('merge-confirmation-modal');
  }
  public get newInputName() {
    return this.placeHolder;
  }
  public set newInputName(val) {
    this.placeHolder = val;
  }
  private get disableConfirm() {
    return this.newInputName === '';
  }
  private async confirm(): Promise<void> {
    await inputsTree.mergeSelectedNodes(this.newInputName).then(() => this.mergeComplete());
    this.$bvModal.hide('merge-confirmation-modal');
    await inputsTree.loadTree(inputsTree.rootAsset);
  }
  @Emit('merge-complete')
  public mergeComplete() {}
}
