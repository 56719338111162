






































































































































































// Libraries
import { Component, Vue, VModel, Prop, Emit, Watch } from "vue-property-decorator";
import { TreeTraverseHelper } from "@/utils/tree/tree-traverse-helper";
import { alphabeticSorter } from "@/utils/array-utils";
import { Nil } from "@/utils/types";
// View Models
import { ITreeNode, TreeNodesTable } from "@/view-models/tree";
// Components
import IconButton from "@/components/dsm/IconButton.vue";
import { Events, eventBus } from "@/utils/event-bus";
// store
import inputsTree from "@/store/inputs-tree";
import {
  IAssetReportVariableViewModel,
  IVariableDataRequestViewModel,
  IVariablesLatestDataRequestViewModel,
  VariableTreeNode,
} from "@/view-models/variables";
import editor from "@/store/editor";
import { CurrentMode } from "@/enums/current-mode";
import app from "@/store/app";
import { ModelEfficacyMetrics, TreeFilterEnum } from "@/enums/variables";

@Component({
  name: "tree-node",
  components: {
    IconButton,
  },
})
export default class TreeNode<T> extends Vue {
  // VUE.JS Props
  @VModel({ default: null })
  public node!: Nil<ITreeNode<IAssetReportVariableViewModel>>;

  @Prop({ required: true })
  public nodesTable!: TreeNodesTable<T>;

  @Prop({ required: true })
  public latestChecked!:boolean;
  public showInfo = false;
  public showDeleteInfo = false;

  // Refs
  // VUEX
   private get treeHelper(): TreeTraverseHelper<T> {
    return new TreeTraverseHelper<T>(this.nodesTable);
  }
  private get isNodeChildren(): boolean {
    return this.node.isLeaf;
  }
  private get isMerged(): boolean {
    return this.node.data.isMerged;
  }
  private get isNew(): boolean {
    return !this.node.data.manuallyUpdated;
  }
  private get isCustom(): boolean {
    return this.node.data.type === TreeFilterEnum.Custom;
  }
  private get isSelected(): boolean {
    return editor.currentMode === CurrentMode.Edit && inputsTree.selectedNode
      ? this.node.key === inputsTree.selectedNode.key
      : false;
  }

  // Properties
  public currentVariableMode = CurrentMode;
  public latest:boolean=true;
  public processing:boolean= false;
  public latestValue:string = '';
  public isNoData:boolean = false;
  // Fields
  // Stores
  public editorStore = editor;
  // Getters
  public get isInMergePhase(): boolean {
    return inputsTree.inMergePhase;
  }

  public get hasNode(): boolean {
    return this.node != null;
  }
  public get toggleIconClass(): string {
    return this.node.isOpen ? "mdi-minus-box-outline" : "mdi-plus-box-outline";
  }
  public get hasChildren(): boolean {
    return this.node.childrenKeys.length > 0;
  }
  public get children(): ITreeNode<T>[] {
    return Array.from(
      new Set(this.treeHelper.getChildrenNodes(this.node) ?? [])
    );
  }
  public get childNodes(): ITreeNode<T>[] {
    const alphaSorter = alphabeticSorter<ITreeNode<T>>((node) => node.name);
    const nodes: ITreeNode<T>[] = [];
    const leaves: ITreeNode<T>[] = [];
    for (const child of this.children) {
      if (child.isLeaf) {
        leaves.push(child);
      } else {
        nodes.push(child);
      }
    }
    nodes.sort(alphaSorter);
    leaves.sort(alphaSorter);

    return [...nodes, ...leaves];
  }

  public get selectedForMerge(): boolean {
    return inputsTree.selectedNodeKeysForMerge.includes(this.node.key);
  }

  public set selectedForMerge(value: boolean) {
    if (value) {
      inputsTree.addNodeKeyForMerge(this.node.key);
    } else {
      inputsTree.removeNodeKeyForMerge(this.node.key);
    }
  }

  public get selectedNodeIsClean(): boolean {
    return inputsTree.selectedNodeIsClean;
  }

  public get customVariableValidation(): boolean {
    return !app.currentUser.isCustomerUser;
  }

  public get currentMode(): string {
    return editor.currentMode;
  }

  public get variablesEligibleForFormula() : boolean {
    return this.node.isLeaf &&
          (this.currentMode === this.currentVariableMode.Create &&
          (this.isMerged || this.node.data?.type === ModelEfficacyMetrics) ||
          this.isInMergePhase && this.isCustom);
  }

  public get currentValidationMsg(): string {
      if (this.isCustom) {
        return this.$t('home.variables.customVariableSelection') as string;
      }
      else if (this.isMerged) {
        return this.$t('home.variables.mergeVariableSelection') as string;
      }
      else if (this.node.data?.type === ModelEfficacyMetrics) {
        return this.$t('home.variables.modelEfficacyMetricsSelection') as string;
      }
  }

  public deleteVariable(node: VariableTreeNode):void{
   eventBus.$emit("delete-node", node);
  }
  public copyVariable(node: VariableTreeNode):void{
   eventBus.$emit("calculator-copy-formula", node);

  }
  public createVariable(node: VariableTreeNode): void {
    editor.setCurrentMode(CurrentMode.Create);
    eventBus.$emit('calculator-clear-formula');
    inputsTree.setCustomParentNode(node);
  }

  public showInfoTooltip(): void {
    this.showInfo = true;
  }

  public hideInfoTooltip(): void {
    this.showInfo = false;
  }

  public showDeleteInfoTooltip(): void {
    this.showDeleteInfo = true;
  }

  public hideDeleteInfoTooltip(): void {
    this.showDeleteInfo = false;
  }

   public async showLatestValue(node: VariableTreeNode):Promise<void>{
   this.latest=false;
   this.processing=true;
   let variableRequestPayLoad: IVariableDataRequestViewModel;
   variableRequestPayLoad= {
          key: node.data.variableKey,
          unitOfMeasurement: ''
    };
   let payload:IVariablesLatestDataRequestViewModel;
   payload = {
      variables: [variableRequestPayLoad],
      measurementSystemKey : ''
    };
    try {
          const result = await this.editorStore.GetLatestValue(
            payload);
            if (result.length > 0 && result[0].data.length > 0)
            {
              const dataObject = result[0].data[0];
              if (dataObject.length > 1 && dataObject[1] != '')              {
              this.latestValue = dataObject[1];
              }
            }
               }
     catch (err) {

        }
        if (this.latestValue === '')
        {
          this.latestValue = "No Data";
          this.isNoData = true;
        }
        this.processing = false;
  }



  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // public mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public toggleNodeExpansion() {
    eventBus.$emit(Events.VariableTreeNodeToggleIsOpen, this.node);
  }
  public selectCurrentNode(node: VariableTreeNode): void {
    if (this.isNodeChildren && !this.isSelected) {
      if (this.selectedNodeIsClean) {
        inputsTree.setSelectedNode(node);
      } else {
        if (editor.currentMode !== CurrentMode.Create) {
        this.showUnsavedModal(() => inputsTree.setSelectedNode(node));
        }
      }
      if (this.customVariableValidation) {
      eventBus.$emit("selected-node", node);
      }
    }
  }
  // Watchers
  @Watch('latestChecked')
  public latestCheckedChanged() {
    if (!this.latestChecked) {
      this.latestValue = '';
      this.isNoData = false;
      this.processing = false;
      this.latest = true;
    }
  }
  // Emitters
  @Emit("show-unsaved-modal")
  public showUnsavedModal(returnFunction: any) {}
}
